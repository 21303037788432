import React from "react";
import { Link } from "react-router-dom";
import "./featuredBlock.css";

export default function FeaturedBlockType2(props) {
  // Check if the link is clickable based on some condition
  const isClickable = props.isClickable; // replace with your actual prop name

  // Define tags content
  const tagsContent = isClickable
    ? ["🔗", ...props.tags] // add "clickable" if isClickable is true
    : props.tags; // use existing tags if isClickable is false

  // Use an if statement or a ternary operator
  let content;
  if (isClickable) {
    content = (
      <Link to={props.path}>
        <div className="featured-block">
          <div className="featured__block__content">
            <div className="featured-block--info">
              <div className="featured-block--title">
                <h3>{props.title}</h3>
              </div>
              <div className="featured-block--info-tags">
                <ul className="tags">
                  {tagsContent.map((tag, i) => (
                    <li key={i}>{tag}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="featured-block--media">
              <img
                src={props.imgSrc}
                alt={props.imgAlt}
                style={{ width: "100%" }}
                className="video--play-when-visible"
              />
            </div>
          </div>
        </div>
      </Link>
    );
  } else {
    content = (
      <div className="featured-block">
        <div className="featured__block__content">
          <div className="featured-block--info">
            <div className="featured-block--title">
              <h3>{props.title}</h3>
            </div>
            <div className="featured-block--info-tags">
              <ul className="tags">
                {tagsContent.map((tag, i) => (
                  <li key={i}>{tag}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="featured-block--media">
            <img
              src={props.imgSrc}
              alt={props.imgAlt}
              style={{ width: "100%" }}
              className="video--play-when-visible"
            />
          </div>
        </div>
      </div>
    );
  }

  return content;
}
