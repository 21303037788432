export const rendsHifi = [
  {
    src: "../media/rends-hifi/1.png",
    width: 3,
    height: 3,
  },
  {
    src: "../media/rends-hifi/2.png",
    width: 4,
    height: 2.5,
  },
  {
    src: "../media/rends-hifi/3.png",
    width: 2.8,
    height: 5,
  },
  {
    src: "../media/rends-hifi/4.png",
    width: 3,
    height: 3,
  },
  {
    src: "../media/rends-hifi/5.png",
    width: 2.5,
    height: 4,
  },
  {
    src: "../media/rends-hifi/6.png",
    width: 2.5,
    height: 5,
  },
  // {
  //   src: "../media/rends-hifi/7.png",
  //   width: 2.8,
  //   height: 3,
  // },
  {
    src: "../media/rends-hifi/8.png",
    width: 3,
    height: 5,
  },
  {
    src: "../media/rends-hifi/9.png",
    width: 4,
    height: 2.8,
  },
];
