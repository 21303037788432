import React from "react";
import styled from "styled-components";

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(10px);
`;

const WhenIUse = styled.div`
  top: 0px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: white;
  padding: 16px;

  @media screen and (max-width: 600px) {
    font-size: 28px;
    padding: 8px;
  }
`;

const ExploreMyPortfolio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--body-text-size);
  color: var(--primary);

  @media screen and (max-width: 600px) {
    padding: 8px;
  }
`;

const WhenIUseProfessionalSkillsParent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  z-index: 0;

  @media screen and (max-width: 600px) {
    justify-content: flex-end;
  }
`;

const ArrowIcon = styled.img`
  position: relative;
  width: 26px;
  height: 30px;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    width: 24px;
    height: 28px;
  }
`;

const ButtonCircleArrow = styled.div`
  margin: 0 !important;
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--br-71xl);
  background-color: var(--primary);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;

  @media screen and (max-width: 600px) {
    bottom: 16px;
    width: 52px;
    height: 52px;
  }
`;

const FrameParentRoot = styled.div`
  align-self: stretch;
  height: 464px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-3xs);
  z-index: 1;
  text-align: center;
  font-size: var(--heading-size);
  color: var(--color-dimgray);
  font-family: var(--buttons-text);
  position: relative;

  @media screen and (max-width: 600px) {
    height: 300px;
  }
`;

const SectionWithProfessionalSkills = ({
  titleText,
  subtitleText,
  onButtonCircleArrowContainerClick,
  src,
}) => {
  return (
    <FrameParentRoot>
      <VideoBackground autoPlay muted loop>
        {/* Replace the following line with the actual path to your video file */}
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoBackground>

      <WhenIUseProfessionalSkillsParent>
        <WhenIUse>{titleText}</WhenIUse>
        <ExploreMyPortfolio>{subtitleText}</ExploreMyPortfolio>
      </WhenIUseProfessionalSkillsParent>

      <ButtonCircleArrow onClick={onButtonCircleArrowContainerClick}>
        <ArrowIcon alt="" src="/arrow@2x.png" />
      </ButtonCircleArrow>
    </FrameParentRoot>
  );
};

export default SectionWithProfessionalSkills;
