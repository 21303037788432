import { useEffect, lazy, Suspense } from "react";

import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Nav from "./components/nav/Nav";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Fun from "./pages/Fun";
import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";
import Projects from "./pages/Projects";
import Boratas from "./pages/Boratas";
import Rends from "./pages/Rends";
import Rezcult from "./pages/Rezcult";
import Tayfun from "./pages/Tayfun";

const PhotoCollection = lazy(() => import("./pages/PhotoCollection"));
const NotFound = lazy(() => import("./pages/NotFound"));
const AlbumAlienWonders = lazy(() => import("./pages/AlbumAlienWonders"));
const AlbumFleshNGears = lazy(() => import("./pages/AlbumFleshNGears"));
const AlbumAround = lazy(() => import("./pages/AlbumAround"));
const AlbumGolden = lazy(() => import("./pages/AlbumGolden"));
const AlbumShapesMatter = lazy(() => import("./pages/AlbumShapesMatter"));
const AlbumGreenNBlues = lazy(() => import("./pages/AlbumGreenNBlues"));
const Embracing = lazy(() => import("./pages/featured-pages/Embracing"));
const Pengs = lazy(() => import("./pages/featured-pages/Pengs"));
const BluishLemons = lazy(() => import("./pages/featured-pages/BluishLemons"));
const Saturn = lazy(() => import("./pages/featured-pages/Saturn"));
const CoolCat = lazy(() => import("./pages/featured-pages/CoolCat"));
const Dude = lazy(() => import("./pages/featured-pages/Dude"));
const NeonMoon = lazy(() => import("./pages/featured-pages/NeonMoon"));
const SarahLynn = lazy(() => import("./pages/featured-pages/SarahLynn"));
const Equipoise = lazy(() => import("./pages/featured-pages/Equipoise"));
const ToiletPaper = lazy(() => import("./pages/featured-pages/ToiletPaper"));
const RedCd = lazy(() => import("./pages/featured-pages/RedCd"));
const Cruart = lazy(() => import("./pages/featured-pages/Cruart"));
const Tak = lazy(() => import("./pages/featured-pages/Tak"));
const P311 = lazy(() => import("./pages/featured-pages/P311"));
const Izmir100 = lazy(() => import("./pages/featured-pages/Izmir100"));
const Number9 = lazy(() => import("./pages/featured-pages/Number9"));
const AIOkeeffe = lazy(() => import("./pages/featured-pages/AIOkeeffe"));
const AITinyBodyParts = lazy(() =>
  import("./pages/featured-pages/AITinyBodyParts")
);

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Kousha's Portfolio";
        metaDescription = "";
        break;
      case "/fun":
        title = "";
        metaDescription = "";
        break;
      case "/boratas":
        title = "";
        metaDescription = "";
        break;
      case "/about":
        title = "";
        metaDescription = "";
        break;
      case "/contact":
        title = "";
        metaDescription = "";
        break;
      case "/projects":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/fun" element={<Fun />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/boratas" element={<Boratas />} />
        <Route path="/projects/rends" element={<Rends />} />
        <Route path="/projects/rezcult" element={<Rezcult />} />
        <Route path="/projects/tayfun" element={<Tayfun />} />

        <Route path="/photo-collection">
          <Route
            index
            element={
              <Suspense fallback={<div>LOADING</div>}>
                <PhotoCollection />
              </Suspense>
            }
          />
          <Route
            path="alien-wonders"
            element={
              <Suspense fallback={<div>LOADING</div>}>
                <AlbumAlienWonders />
              </Suspense>
            }
          />
          <Route
            path="flesh-n-gears"
            element={
              <Suspense fallback={<div>LOADING</div>}>
                <AlbumFleshNGears />
              </Suspense>
            }
          />
          <Route
            path="around"
            element={
              <Suspense fallback={<div>LOADING</div>}>
                <AlbumAround />
              </Suspense>
            }
          />
          <Route
            path="golden"
            element={
              <Suspense fallback={<div>LOADING</div>}>
                <AlbumGolden />
              </Suspense>
            }
          />
          <Route
            path="shapes-matter"
            element={
              <Suspense fallback={<div>LOADING</div>}>
                <AlbumShapesMatter />
              </Suspense>
            }
          />
          <Route
            path="green-n-blues"
            element={
              <Suspense fallback={<div>LOADING</div>}>
                <AlbumGreenNBlues />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="fun/embracing-our-differences"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <Embracing />
            </Suspense>
          }
        />
        <Route
          path="fun/penguins-can-not-fly"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <Pengs />
            </Suspense>
          }
        />
        <Route
          path="fun/bluish-lemons"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <BluishLemons />
            </Suspense>
          }
        />
        <Route
          path="fun/saturn-cafe"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <Saturn />
            </Suspense>
          }
        />
        <Route
          path="fun/cool-cat"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <CoolCat />
            </Suspense>
          }
        />
        <Route
          path="fun/dude"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <Dude />
            </Suspense>
          }
        />
        <Route
          path="fun/neon-moon"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <NeonMoon />
            </Suspense>
          }
        />
        <Route
          path="fun/sarah-lynn"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <SarahLynn />
            </Suspense>
          }
        />
        <Route
          path="fun/toilet-paper"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <ToiletPaper />
            </Suspense>
          }
        />
        <Route
          path="fun/red-cd"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <RedCd />
            </Suspense>
          }
        />
        <Route
          path="fun/cruart"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <Cruart />
            </Suspense>
          }
        />
        <Route
          path="fun/izmir-100music"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <Izmir100 />
            </Suspense>
          }
        />
        <Route
          path="fun/number9"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <Number9 />
            </Suspense>
          }
        />
        <Route
          path="fun/ai-okeeffe"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <AIOkeeffe />
            </Suspense>
          }
        />
        <Route
          path="fun/ai-tiny-body-parts"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <AITinyBodyParts />
            </Suspense>
          }
        />
        <Route
          path="fun/NO311"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <P311 />
            </Suspense>
          }
        />
        <Route
          path="fun/equipoise"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <Equipoise />
            </Suspense>
          }
        />
        <Route
          path="fun/tak-logo"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <Tak />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<div>LOADING</div>}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
      {pathname !== "/" && <Footer />}
    </>
  );
}
export default App;
