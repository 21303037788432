import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ExperienceBlock from "../components/ExperienceBlock";
import SectionWithProfessionalSkills from "../components/SectionWithProfessionalSkills";
import styled from "styled-components";
import ScrollIndicator from "../helpers/ScrollIndicator";

const RectangleParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-141xl) 0px;
  gap: var(--gap-37xl);
  z-index: 2;

  @media screen and (max-width: 600px) {
    gap: 32px;
  }
`;

const ProjectsRoot = styled.div`
  position: relative;
  background-color: var(--dark-background);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Projects = () => {
  const navigate = useNavigate();

  const onButtonCircleArrowContainerClick = useCallback(() => {
    const anchor = document.querySelector(".scrollpoint");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onRectangleImageClick = useCallback(() => {
    navigate("/desktop-boratas");
  }, [navigate]);

  return (
    <>
      <ScrollIndicator color="var(--primary)" />

      <ProjectsRoot>
        <SectionWithProfessionalSkills
          titleText="Creativity & Strategy"
          subtitleText="Multimedia designs, innovative marketing
        solutions, and captivating digital content"
          src="/media/12.mp4"
          onButtonCircleArrowContainerClick={onButtonCircleArrowContainerClick}
        />
        <RectangleParent className="scrollpoint">
          <ExperienceBlock
            title="Boratas"
            url="boratas"
            position="Multimedia Design"
            date="2020 - Present"
            tags={[
              "direction",
              "brand identity",
              "digital design",
              "print design",
              "graphic",
              "video",
              "3D",
              "animation",
              "web design",
              "social media",
            ]}
            videoSrc="../media/comp-1-1mb_1.mp4"
          />
          <ExperienceBlock
            className="ExperienceBlockBoratas"
            title="Rends"
            url="rends"
            position="Full-Stack Design"
            date="2023 - In Progress"
            tags={[
              "ui/ux design",
              "web design",
              "graphic assets",
              "iconography",
              "front-end development",
            ]}
            videoSrc="../media/comp-5.mp4"
          />
          <ExperienceBlock
            className="ExperienceBlockBoratas"
            title="Rezcult"
            url="rezcult"
            position="Digital Design"
            date="2023"
            tags={[
              "brand design",
              "logo design",
              "graphic design",
              "video",
              "motion graphic",
              "social media content",
            ]}
            videoSrc="../media/comp-3_1.mp4"
          />
          <ExperienceBlock
            className="ExperienceBlockBoratas"
            title="Baris Tayfun"
            url="tayfun"
            position="Identity & Web Design"
            date="2022"
            tags={[
              "indentity design",
              "web design",
              "web development",
              "interactive design",
            ]}
            videoSrc="../media/comp-4.mp4"
          />
        </RectangleParent>
      </ProjectsRoot>
    </>
  );
};

export default Projects;
