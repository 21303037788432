import { React, useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ScrollIndicator from "../helpers/ScrollIndicator";

import "./tayfun.css";

import { MdOutlineContactPage } from "react-icons/md";
import { MdOutlineArrowBack } from "react-icons/md";

import stylescape1 from "../components/tayfun/tayfun-assets/Baris-Tayfun---The-Writer---StylescapesArtboard-1.jpg";
import stylescape2 from "../components/tayfun/tayfun-assets/Baris-Tayfun---The-Writer---StylescapesArtboard-2.jpg";
import stylescape3 from "../components/tayfun/tayfun-assets/Baris-Tayfun---The-Writer---StylescapesArtboard-3.jpg";
import stylescape4 from "../components/tayfun/tayfun-assets/Baris-Tayfun---The-Writer---StylescapesArtboard-4.jpg";
import designsystem1 from "../components/tayfun/tayfun-assets/Tayfun - Design System Sheet-02.jpg";
import designsystem2 from "../components/tayfun/tayfun-assets/Tayfun - Design System Sheet-03.jpg";
import wireframes from "../components/tayfun/tayfun-assets/Tayfun---wireframes-sheet.jpg";
import hifi from "../components/tayfun/tayfun-assets/Tayfun-Hifi-Design---Present---Sheet-2.jpg";
import bannersvid from "../components/tayfun/tayfun-assets/baris-tayfun-banners-vid.mp4";
import tayfunInit from "../components/tayfun/tayfun-assets/tayfun-intro.jpg";
import ButtonFullWidth from "../components/ButtonFullWidth";

function Tayfun() {
  const myRef = useRef();
  const [isVideoVisible, setIsVideoVisible] = useState();
  // console.log("isVideoVisible", isVideoVisible);

  //data

  // setting interaction observer to observe after load
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsVideoVisible(entry.isIntersecting);
      // console.log("entry", entry);
    });
    observer.observe(myRef.current);
  }, []);

  // play/pause video as visiblity changes
  useEffect(() => {
    if (isVideoVisible) {
      myRef.current.play();
    } else {
      myRef.current.pause();
    }
  }, [isVideoVisible]);
  return (
    <div>
      <ScrollIndicator color="var(--primary)" />

      <div className="tayfun-page-container">
        <section id="tayfun-banner" className="tayfun">
          <div className="tayfun--banner">
            <div className="container tayfun__banner__content">
              <div>
                <h1>
                  In the Lense of Cosmic Transactions,
                  <br />
                  Between Physical and Spritual!
                  <span className="span--font">
                    identity and web design case study
                  </span>
                </h1>
                <h2>Overview</h2>
                <p>
                  Baris Tayfun, an exceptional author. I helped him creating his
                  brand, style guide, website, and captivating media assets to
                  reflect his unique writing style and establish a distinct
                  online presence.
                </p>
              </div>
              <img
                src={tayfunInit}
                alt="rezcult banner"
                className="single-img-banner"
              />
            </div>
          </div>
        </section>

        <section
          id="tayfun-init"
          className="container tayfun-sec tayfun-top-margin"
        >
          <h2>Client's Needs and Goals</h2>
          <div className="tayfun__roll">
            <p>
              Baris needed a dynamic website and a unique brand identity that
              would reflect his essence as a writer and guide visitors on an
              introspective journey through his words. With the goal of not only
              showcasing his written works but also inviting readers to explore
              profound themes, Baris sought a design that resonated with his
              artistic vision. This included an online presence that was
              visually captivating, emotionally engaging, and provided a
              seamless reading experience. Additionally, he envisioned a strong
              social media presence to connect with his audience and keep them
              inspired through thoughtfully designed art banners and social
              media assets.
            </p>
          </div>
        </section>

        <section className="container tayfun-sec">
          <h2>Design Process</h2>
          <div className="threed">
            <h3>Stylescapes</h3>
            <p>
              Before delving into the details of our design, it's essential to
              understand the creative process that led us to the final visual
              identity for Baris Tayfun. The journey began with Stylescapes – a
              unique approach that allowed us to visualize different design
              directions before weaving them into a harmonious tapestry.
            </p>
            <img
              src={stylescape1}
              alt="tayfun stylescapes 1"
              className="single-img"
            />
            <img
              src={stylescape2}
              alt="tayfun stylescapes 2"
              className="single-img"
            />
            <img
              src={stylescape3}
              alt="tayfun stylescapes 3"
              className="single-img"
            />
            <img
              src={stylescape4}
              alt="tayfun stylescapes 4"
              className="single-img"
            />
            <p>
              We had a challenging choice to make, as all Stylescapes held their
              unique charm. However, our collaborative journey led to the
              selection of two Stylescapes (the 2nd and the 3rd one) that would
              be blended to create the author's digital atmosphere, setting the
              stage for his readers' profound explorations.
            </p>
          </div>
        </section>

        <section className="container tayfun-sec">
          <div className="threed">
            <h3>Design Guids</h3>
            <p>
              Once we had the Stylescapes in place, the next step was to create
              a style guide. This system was like a rulebook for how everything
              on Baris Tayfun's digital space should look and feel. It was not
              just about colors and fonts; it was about capturing the mood of
              his writing.
            </p>
            <div className="threed">
              <p>
                <span style={{ color: "var(--primary)" }}>
                  Fonts and Colors
                </span>
                <br />
                We started by choosing fonts, typefaces and colors. We wanted
                these to match Baris's writing style and the feelings he wanted
                to convey. We went for easy-to-read serif typeface "Lora" for
                body texts, and a more modern sans-serif typeface "Oswald" for
                headers and titles. We calmed colors with deep blue tone as
                primary and secondary, and a red tone as accent.
              </p>
              <img
                src={designsystem1}
                alt="rezcult plan illustration"
                className="single-img"
              />
            </div>
            <div className="threed">
              <p>
                <span style={{ color: "var(--primary)" }}>Floating Words</span>
                <br />
                One unique part of our design was putting words in the images,
                like magic portals. They could transport you to different
                places. We wanted to show that his stories could come to life
                visually, not just in words. So, we made words look like they
                were floating in the air between the images. This was our
                special touch to make the design extra interesting.
              </p>
              <img
                src={designsystem2}
                alt="rezcult plan illustration"
                className="single-img"
              />
            </div>
            <div className="threed">
              <p>
                <span style={{ color: "var(--primary)" }}>Keep It Simple</span>
                <br />
                We believed in keeping things simple. Simple design makes it
                easy to focus on what's important: Baris's writing. Every
                element was carefully placed to create a clean and
                distraction-free reading experience. This approach also helped
                highlight the floating words, making readers feel like they were
                part of the story.
              </p>
              <p>
                This was the guiding light for our project. It captured Baris
                Tayfun's style and the themes of his stories. It made everything
                consistent, from the website to art banners and social media
                stuff.
              </p>
            </div>
          </div>
        </section>

        <section className="container tayfun-sec">
          <div className="threed">
            <h3>Website Design & Development</h3>
          </div>
          <div className="threed">
            <p>
              <span style={{ color: "var(--primary)" }}>Lofi Design</span>
              <br />
              With the design guidlines as our roadmap, the next challenge was
              to translate these ideas into a functioning website. We started
              with wireframes, which are the skeleton of the website. They help
              us see where everything should go without worrying too much about
              colors and pictures.
            </p>
            <p>
              We kept our goals front and center. What was the main purpose of
              this website? The wireframes didn't just show where things should
              be; they also mapped out how things should stack, shift, and flow
              on smaller screens. This way, we made sure that Baris's world was
              accessible to everyone, no matter what device they were using.
            </p>
            <img
              src={wireframes}
              alt="rezcult plan illustration"
              className="single-img"
            />
          </div>
          <div className="threed">
            <p>
              <span style={{ color: "var(--primary)" }}>Hifi Design</span>
              <br />
              With wireframes in place and the goals firmly defined, it was time
              to breathe life into the website. We stuck to our design
              guidelines. The colors, fonts, and the idea of weaving words into
              images were consistently applied. This gave the website a unified,
              polished look, no matter where you clicked.
            </p>
            <p>
              The website not only represented Baris but also gave readers a
              taste of what his books would be like. It was an immersive
              experience, from the elegant homepage to the tranquil blog pages.
              With high-fidelity design, Baris's words and world came to life on
              the digital stage.
            </p>
            <img
              src={hifi}
              alt="rezcult plan illustration"
              className="single-img"
            />
          </div>
          <div className="threed">
            <p>
              <span style={{ color: "var(--primary)" }}>Extra Salt</span>
              <br />
              The header banners of each page are more than just visual
              elements; they are the salt and pepper that season the website,
              making it distinct and unforgettable. As visitors explore the
              website, they are greeted with layers of images that subtly reveal
              words floating in the air. These words are strategically placed
              between these layers, creating an illusion of depth and movement.
            </p>
            <p>
              As visitors explore the website, they are greeted with layers of
              images that subtly reveal words floating in the air. These words
              are strategically placed between these layers, creating an
              illusion of depth and movement. They beckon readers to embark on a
              journey, to discover the stories that lie within.
            </p>
            <video
              playsInline
              muted
              loop
              className="video--play-when-visible"
              id="video"
              ref={myRef}
              src={bannersvid}
              type="media/video/mp4"
            />
          </div>
          <div className="threed">
            <p>
              <span style={{ color: "var(--primary)" }}>Development</span>
              <br />
              ReactJS was the engine that powered the interactive elements of
              the website. It allowed for seamless navigation and real-time
              response to user interactions. Each component, whether it was the
              dynamic header banners, responsive layouts, or the immersive
              storytelling experience, was crafted using React components. This
              not only enhanced the user experience but also made it easier to
              manage and scale the project.
            </p>
            <p>
              A noteworthy aspect of this project was the integration of the
              Locofy plugin, a Figma-to-React converter. For the first time, I
              employed this innovative tool to bridge the gap between design and
              development. Locofy expedited the initial React structure's
              creation by automatically converting Figma design elements into
              React components. While the tool provided a solid starting point,
              it's worth noting that extensive adjustments in terms of CSS and
              functionality were necessary to align the design with the
              project's unique requirements.
            </p>
          </div>
        </section>

        <section
          id="tayfun-conclusion"
          className="container tayfun-sec rez-top-margin"
        >
          <h2>Conclusion</h2>
          <p>
            From the fusion of Stylescapes to the creation of a unique design,
            we incorporated fonts, colors, and the intriguing concept of
            floating words among images, forging a distinctive design element.
            In the web design process, leveraging tools like the Locofy plugin,
            I streamlined the transition from Figma to React, saving precious
            time and enhancing efficiency.
          </p>
          <p>
            In the project's future, IMHO, enhancing interactivity with features
            like a reader's community and immersive storytelling experiences
            could enrich the user experience. Keeping the website dynamic
            through regular content updates, blog posts, and engaging social
            media campaigns will be pivotal for sustaining Baris Tayfun's online
            presence and connecting with an expanding audience.
          </p>
        </section>

        <section id="buts" className="container">
          <ButtonFullWidth
            buttonText="Go Back To Projects"
            linkTo="/Projects"
          />
        </section>
      </div>
    </div>
  );
}

export default Tayfun;
