import styled from "styled-components";
import navData from "./navData.js";
import { useState, useEffect } from "react";
import navIconImg from "./nav-assetes/nav-image.png";
import { Link, useLocation } from "react-router-dom";
import { RiMenuLine, RiMenuSearchLine } from "react-icons/ri";

const NavRoot = styled.div`
  background: transparent;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  color: white;
  position: sticky;
  top: 0;
  height: 60px;
  z-index: 200;
  font-family: "Poppins", sans-serif;

  transition: height 0.3s ease-in-out;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(19, 19, 19, 0.5);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    z-index: 190;
  }
`;

const NavContent = styled.div`
  width: var(--max-width-desktop);
  margin: 0 8px;
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 210;

  @media screen and (max-width: 600px) {
    padding-top: 8px;
  }
`;

const NavIcon = styled.div`
  z-index: 210;
  animation: fade-in 0.5s;
`;

const NavIconImg = styled.img`
  aspect-ratio: 1/1;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
`;

const NavbarLinksList = styled.ul`
  display: flex;
  align-items: center;
  gap: 10px;
  list-style: none;
  z-index: 210;
  max-height: ${(props) => (props.menuActive ? "150vh" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    position: absolute;
    font-size: 22px;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(19, 19, 19, 0.5);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);

    &.active {
      max-height: 100vh;
    }

    li {
      padding: 10px;
      text-align: center;

      a {
        color: white;
        text-decoration: none;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  @media screen and (min-width: 601px) {
    flex-direction: row;
    visibility: visible;
    opacity: 1;
    max-height: none;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  list-style: none;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    position: absolute;
    left: 0;
    width: 100%;
    padding-top: 80px;
  }
`;

const NavbarLinkItem = styled.li`
  padding: 5px;

  a {
    text-decoration: none;
    color: ${(props) => (props.active ? "var(--primary)" : "white")};
    transition: color 0.3s; /* Add a smooth transition effect */

    &:hover {
      color: var(--primary);
      text-decoration: underline; /* Adjust your hover styles here */
    }
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  cursor: pointer;
  font-size: 24px;
  @media screen and (max-width: 600px) {
    display: block;

    &.active {
      color: var(--primary);
    }
  }
`;

// ... (existing imports)

const Nav = () => {
  const [navIconTxt, setNavIconTxt] = useState("Welcome");
  const [menuActive, setMenuActive] = useState(false);
  const currentPath = useLocation().pathname;

  useEffect(() => {
    const links = document.querySelectorAll(".navbar__links-list a");

    links.forEach((link) => {
      const linkPath = link.getAttribute("href");

      if (linkPath === currentPath) {
        link.style.color = "var(--primary)";
      } else {
        link.style.color = "inherit";
      }
    });
  }, [currentPath]);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const handleNavLinkClick = () => {
    setMenuActive(false);
  };

  return (
    <NavRoot>
      <NavContent>
        <NavIcon>
          {currentPath === "/" ? (
            navIconTxt
          ) : (
            <Link to={"/"}>
              <NavIconImg src={navIconImg} alt="nav icon" />
            </Link>
          )}
        </NavIcon>
        <NavbarLinksList
          className={`navbar__links-list ${menuActive ? "active" : ""}`}
          menuActive={menuActive}
        >
          <LinksContainer>
            {navData.map((item) => (
              <NavbarLinkItem
                key={item.id}
                active={currentPath === item.link}
                onClick={handleNavLinkClick}
              >
                <Link to={item.link}>{item.title}</Link>
              </NavbarLinkItem>
            ))}
          </LinksContainer>
        </NavbarLinksList>
        <HamburgerIcon
          onClick={toggleMenu}
          className={menuActive ? "active" : ""}
        >
          {menuActive ? <RiMenuSearchLine /> : <RiMenuLine />}
        </HamburgerIcon>
      </NavContent>
    </NavRoot>
  );
};

export default Nav;
