import React, { useRef, useEffect } from "react";

import { TweenMax, TimelineLite, Power3 } from "gsap";

import CTA from "../components/CTA.jsx";
import ButtonWrappedWidth from "../components/ButtonWrappedWidth.jsx";
import "./hero.scss";

import { MdOutlineArrowForward } from "react-icons/md";

export default function Hero() {
  let hero = useRef(null);
  let images = useRef(null);
  let content = useRef(null);
  let tl = new TimelineLite({ delay: 0.2 });
  let animationRun = useRef(false);

  useEffect(() => {
    if (!animationRun.current) {
      // IMAGE VARS
      const myImg = images.firstElementChild;
      TweenMax.to(hero, 0, { css: { visibility: "visible" } });
      // CONTENT VARS
      const headlineFirst = content.children[0].children[0];
      const headlineSecond = headlineFirst.nextSibling;
      const contentP = content.children[1];
      const contentButton = content.children[2];

      // IMAGE ANIMATION
      tl.from(myImg, 1.2, { y: 1280, ease: Power3.easeOut }, "start").from(
        myImg,
        2,
        { scale: 1.6, ease: Power3.easeOut },
        0.2
      );

      // CONTENT ANIMATION
      tl.staggerFrom(
        [headlineFirst.children, headlineSecond.children],
        1,
        {
          y: 44,
          ease: Power3.easeOut,
          delay: 0.6,
        },
        0.15,
        "start"
      )
        .from(contentP, 1, { y: 20, opacity: 0, ease: Power3.easeOut }, 1.2)
        .from(
          contentButton,
          1,
          { y: 20, opacity: 0, ease: Power3.easeOut },
          1.4
        );

      animationRun.current = true;
    }
  }, [tl]);

  let sibling = useRef(null);
  let image = useRef(null);
  useEffect(() => {
    function handleResize() {
      let siblingHeight = sibling.getBoundingClientRect().height;
      image.style.height = `${siblingHeight}px`;
      // console.log(siblingHeight);
      console.log(image.style.height);
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header id="portfolio" className="hero" ref={(el) => (hero = el)}>
      <div className="container hero__container">
        <div className="hero__inner">
          <div className="hero__content" ref={(el) => (sibling = el)}>
            <div className="hero__content__inner" ref={(el) => (content = el)}>
              <h1>
                <div className="hero__content__line">
                  <div className="hero__content__line__inner">
                    I'm Kousha, and here's
                  </div>
                </div>
                <div className="hero__content__line">
                  <div className="hero__content__line__inner">
                    My Creative Corner
                  </div>
                </div>
              </h1>
              <p className="hero__content__p" style={{ fontWeight: "400" }}>
                With 6+ years of journey in Digital Design and a foundation in
                Architecture, I bring a blend of skills and passion to create
                impactful communication solutions across diverse platforms.
                Guided by five core principles—Functionality, Stability,
                Facilitation, Efficiency, and Aesthetics—my designs are crafted
                to not only visually appealing but also serve their intended
                purpose effectively.
              </p>
              <div className="buttons-container">
                <ButtonWrappedWidth
                  buttonText={
                    <>
                      <span>Go To Projects</span>{" "}
                      <MdOutlineArrowForward
                        style={{ fontSize: "32px", paddingLeft: "8px" }}
                      />
                    </>
                  }
                  linkTo="/Projects"
                />
                <CTA />
              </div>
            </div>
          </div>
          <div className="hero__images" ref={(el) => (image = el)}>
            <div className="hero__images__inner" ref={(el) => (images = el)}>
              <div className="hero__img">
                {/* <img src={profileImg} alt='profile' /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
