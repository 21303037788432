export const photos = [
  {
    src: "../media/rezcult-graphics/1.jpg",
    width: 3,
    height: 3,
  },
  {
    src: "../media/rezcult-graphics/2.jpg",
    width: 3,
    height: 3,
  },
  {
    src: "../media/rezcult-graphics/3.jpg",
    width: 3,
    height: 3,
  },
  {
    src: "../media/rezcult-graphics/4.jpg",
    width: 3,
    height: 3,
  },
  {
    src: "../media/rezcult-graphics/5.jpg",
    width: 3,
    height: 3,
  },
  {
    src: "../media/rezcult-graphics/7.jpg",
    width: 3,
    height: 3,
  },
  {
    src: "../media/rezcult-graphics/6.jpg",
    width: 3,
    height: 3,
  },
];
