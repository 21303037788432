import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./featuredBlock.css";

export default function FeaturedBlock(props) {
  const videoRef = useRef();
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  // Setting up the intersection observer to check if the video is visible
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsVideoVisible(entry.isIntersecting);
    });

    observer.observe(videoRef.current);

    return () => {
      observer.disconnect(); // Cleanup the observer on component unmount
    };
  }, []);

  // Play/pause the video based on visibility
  useEffect(() => {
    const video = videoRef.current;

    if (isVideoVisible) {
      video
        .play()
        .catch((error) => console.error("Error playing video:", error));
    } else {
      video.pause();
    }
  }, [isVideoVisible]);

  // Check if the link is clickable based on some condition
  const isClickable = props.isClickable; // replace with your actual prop name

  // Define tags content
  const tagsContent = isClickable
    ? ["🔗", ...props.tags] // add "clickable" if isClickable is true
    : props.tags; // use existing tags if isClickable is false

  // Use an if statement or a ternary operator
  let content;
  if (isClickable) {
    content = (
      <Link to={props.path}>
        <div className="featured-block">
          <div className="featured__block__content">
            <div className="featured-block--info">
              <div className="featured-block--title">
                <h3>{props.title}</h3>
              </div>
              <div className="featured-block--info-tags">
                <ul className="tags">
                  {tagsContent.map((tag, i) => (
                    <li key={i}>{tag}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="featured-block--media">
              <video
                ref={videoRef}
                src={props.videoSrc}
                type="video/mp4"
                muted
                loop
                playsInline
                className="video--play-when-visible"
              />
            </div>
          </div>
        </div>
      </Link>
    );
  } else {
    content = (
      <div className="featured-block">
        <div className="featured__block__content">
          <div className="featured-block--info">
            <div className="featured-block--title">
              <h3>{props.title}</h3>
            </div>
            <div className="featured-block--info-tags">
              <ul className="tags">
                {tagsContent.map((tag, i) => (
                  <li key={i}>{tag}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="featured-block--media">
            <video
              ref={videoRef}
              src={props.videoSrc}
              type="video/mp4"
              muted
              loop
              playsInline
              className="video--play-when-visible"
            />
          </div>
        </div>
      </div>
    );
  }

  return content;
}
