import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import Me from "./footer-assets/profile-footer-webp.webp";
import { BsLinkedin, BsInstagram, BsGithub, BsBehance } from "react-icons/bs";

const StyledFooter = styled.footer`
  padding: 48px 0;
  width: 100%;
  text-align: center;
  font-size: 14.4px;
  margin-top: 64px;

  @media screen and (max-width: 600px) {
    margin-top: 0px;
  }
`;

const LogoLink = styled(Link)`
  width: 112px;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 32px;
  display: inline-block;
  border-radius: 50%;
`;

const LogoImage = styled.img`
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 64px;
`;

const SocialLink = styled.a`
  padding: 12.8px;
  font-size: 32px;
  border-radius: 0.112px;
  display: flex;
  border: 1px solid transparent;
  color: var(--primary-alt);

  &:hover {
    background: transparent;
    color: var(--primary);
  }
`;

const Copyright = styled.div`
  color: var(--primary);
`;

const Footer = () => {
  const currentPath = useLocation().pathname;

  return (
    <StyledFooter>
      {currentPath !== "/" ? (
        <LogoLink to={"/"} className="footer__logo">
          <LogoImage src={Me} alt="me" />
        </LogoLink>
      ) : (
        <LogoLink href="#portfolio" className="footer__logo">
          <LogoImage src={Me} alt="me" />
        </LogoLink>
      )}

      <SocialLinks>
        <SocialLink
          href="https://www.linkedin.com/in/kousha-yeganeh/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsLinkedin />
        </SocialLink>
        <SocialLink
          href="https://www.instagram.com/k_ush_y_gan_h/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsInstagram />
        </SocialLink>
        <SocialLink
          href="https://github.com/koushayeganeh"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsGithub />
        </SocialLink>
        <SocialLink
          href="https://www.behance.net/koushayeganeh"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsBehance />
        </SocialLink>
      </SocialLinks>

      <Copyright>
        <small>&copy; Kousha Yeganeh. All rights reserved.</small>
      </Copyright>
    </StyledFooter>
  );
};

export default Footer;
