import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MdOutlineContactPage, MdOutlineArrowBack } from "react-icons/md";

const ButtonRoot = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
  background-color: var(--primary);
  color: white;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: var(--primary);
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: inherit;
`;

const IconsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin-bottom: 8px; /* Adjust margin as needed */
`;

const ButtonFullWidth = ({ buttonText, linkTo }) => {
  return (
    <ButtonRoot>
      <StyledLink to={linkTo}>
        <IconsDiv>
          <MdOutlineContactPage />
          <MdOutlineArrowBack />
        </IconsDiv>
        {buttonText}
      </StyledLink>
    </ButtonRoot>
  );
};

export default ButtonFullWidth;
