import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ExperienceBlock from "../components/ExperienceBlock";
import SectionWithProfessionalSkills from "../components/SectionWithProfessionalSkills";
import Featured from "../components/featured/Featured";
import styled from "styled-components";
import ScrollIndicator from "../helpers/ScrollIndicator";

const RectangleParent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically and horizontally */
  padding: var(--padding-141xl) 0px;
  gap: var(--gap-37xl);
  z-index: 2;

  @media screen and (max-width: 1024px) {
    padding: 8px;
    gap: 0;
    z-index: 2;
  }
`;

const FunRoot = styled.div`
  position: relative;
  background-color: var(--dark-background);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Fun = () => {
  const navigate = useNavigate();

  const onButtonCircleArrowContainerClick = useCallback(() => {
    const anchor = document.querySelector(".scrollpoint");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <ScrollIndicator color="var(--primary)" />

      <FunRoot>
        <SectionWithProfessionalSkills
          titleText=" Small Projects, Big Fun"
          subtitleText="The joy of creation with tiny fun-filled adventures"
          src="/media/13.mp4"
          onButtonCircleArrowContainerClick={onButtonCircleArrowContainerClick}
        />
        <RectangleParent className="scrollpoint">
          <Featured />
        </RectangleParent>
      </FunRoot>
    </>
  );
};

export default Fun;
