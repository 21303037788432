export const photos = [
  {
    src: "../media/boratas-photo-examples/1.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "../media/boratas-photo-examples/2.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "../media/boratas-photo-examples/3.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "../media/boratas-photo-examples/4.jpg",
    width: 4,
    height: 3.2,
  },
  {
    src: "../media/boratas-photo-examples/5.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "../media/boratas-photo-examples/6.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "../media/boratas-photo-examples/7.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "../media/boratas-photo-examples/8.jpg",
    width: 1,
    height: 1.3,
  },
  {
    src: "../media/boratas-photo-examples/9.jpg",
    width: 4,
    height: 3,
  },
];
