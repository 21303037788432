export const graphics = [
  {
    src: "../media/boratas-graphics/1.jpg",
    width: 2,
    height: 4.6,
  },
  {
    src: "../media/boratas-graphics/2.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "../media/boratas-graphics/3.jpg",
    width: 4,
    height: 3,
  },
  // {
  //   src: "../media/boratas-graphics/4.jpg",
  //   width: 4,
  //   height: 3,
  // },
  {
    src: "../media/boratas-graphics/10.jpg",
    width: 4,
    height: 3,
  },
  // {
  //   src: "../media/boratas-graphics/6.jpg",
  //   width: 1.45,
  //   height: 1,
  // },
  {
    src: "../media/boratas-graphics/7.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "../media/boratas-graphics/8.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "../media/boratas-graphics/5.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "../media/boratas-graphics/11.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "../media/boratas-graphics/12.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "../media/boratas-graphics/13.jpg",
    width: 4,
    height: 3,
  },
  {
    src: "../media/boratas-graphics/14.jpg",
    width: 4,
    height: 3,
  },
];
