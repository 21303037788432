import React from "react";

import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";

const FIRST_IMAGE = {
  imageUrl: "../media/boratas-slider/after-webp.webp",
};
const SECOND_IMAGE = {
  imageUrl: "../media/boratas-slider/before-webp.webp",
};

const delimiterIconStyles = {
  width: "35px",
  height: "35px",
  backgroundSize: "cover",
  borderRadius: "none",
  background: "var(--color-white)",
};

export default function BoratasSlider() {
  return (
    <ReactBeforeSliderComponent
      delimiterColor={"var(--primary)"}
      delimiterIconStyles={delimiterIconStyles}
      firstImage={FIRST_IMAGE}
      secondImage={SECOND_IMAGE}
    />
  );
}
