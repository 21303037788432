import React from "react";
import { useState, useRef } from "react";
import emailjs from "emailjs-com";
import CTA from "../CTA.jsx";

import "./contact.css";
import { BsWhatsapp } from "react-icons/bs";

function Contact() {
  const form = useRef();
  const [sendMessage, setSendMessage] = useState("Send Message");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_sal6eco",
        "template_aysgstf",
        form.current,
        "LJPfEKk97R3mBzIIc"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    setSendMessage("Message Sent!");
  };

  function resetSubmitText() {
    setSendMessage("Send Message");
  }

  return (
    <section id="contact" className="contact">
      <div className="video-background">
        <video id="contactVideo" autoPlay loop muted>
          <source src="/media/12.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="contact__banner">
        <h2>Let's Have A Talk</h2>
      </div>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <BsWhatsapp className="contact__option-icon" />
            <h4>WhatAapp</h4>
            <h5 className="contact-number">+90 552 898 6458</h5>
            <a
              className="contact-number"
              href="https://web.whatsapp.com/send?phone=+905528986458"
              target="_blank"
              rel="noreferrer"
            >
              Send a message
            </a>
          </article>
          <CTA />
        </div>
        <form ref={form} onSubmit={sendEmail} className="contact__form">
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="text" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          ></textarea>
          <button
            type="submit"
            onMouseLeave={resetSubmitText}
            className="btn btn-primary msg-btn"
          >
            {sendMessage}
          </button>
        </form>
      </div>
    </section>
  );
}

export default Contact;
