import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import About from "../components/about/About";
import styled from "styled-components";
import ScrollIndicator from "../helpers/ScrollIndicator";

const RectangleParent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically and horizontally */
  padding: var(--padding-141xl) 0px;
  gap: var(--gap-37xl);
  z-index: 2;

  @media screen and (max-width: 1024px) {
    max-width: var(--max-width-medium);
    padding: 0;
    gap: 0;
    z-index: 2;
  }
`;

const AboutPageRoot = styled.div`
  position: relative;
  background-color: var(--dark-background);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const AboutPage = () => {
  const navigate = useNavigate();

  const onButtonCircleArrowContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='rectangleImage']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  const onRectangleImageClick = useCallback(() => {
    navigate("/desktop-boratas");
  }, [navigate]);

  return (
    <>
      <ScrollIndicator color="var(--primary)" />

      <AboutPageRoot>
        <RectangleParent>
          <About />
        </RectangleParent>
      </AboutPageRoot>
    </>
  );
};

export default AboutPage;
