import React from "react";
import { Link } from "react-router-dom";
import ScrollIndicator from "../helpers/ScrollIndicator";

import "./rends.css";

import { MdOutlineContactPage } from "react-icons/md";
import { MdOutlineArrowBack } from "react-icons/md";

import RendsGallery from "../components/rends/RendsGallery";
import ButtonFullWidth from "../components/ButtonFullWidth";
import { rendsWire } from "../components/rends/RendsWire";
import { rendsWireResponsive } from "../components/rends/RendsWireResponsive";
import { rendsComp } from "../components/rends/RendsComp";
import { rendsHifi } from "../components/rends/RendsHifi";

import roadmap from "../components/rends/rends-assets/rends-roadmap.jpg";
import sitemap from "../components/rends/rends-assets/rends-sitemap.jpg";
import question from "../components/rends/rends-assets/rends-question.jpg";
import rendsfin from "../components/rends/rends-assets/rends-fin.png";
import rendsInit from "../components/rends/rends-assets/rends-intro.jpg";

function Rends() {
  return (
    <div>
      <ScrollIndicator color="var(--primary)" />

      <div className="rends-page-container">
        <section id="rends-banner" className="rends">
          <div className="rends--banner">
            <div className="container rends__banner__content">
              <div>
                <h1>
                  Elevate Life,
                  <br />
                  Inspire, Connect, Discover
                  <span className="span--font">
                    full stack design case study
                  </span>
                </h1>
                <h2>Overview</h2>
                <p>
                  Rends' is an emerging online magazine that embodies a
                  contemporary identity. Centered around curating multimedia
                  content from diverse corners of the web, this project is an
                  ongoing exploration. Released in quarterly editions, Rends
                  aims to capture the essence of the ever-evolving online
                  landscape, offering a curated collection that speaks to the
                  vibrancy and creativity.
                </p>
              </div>
              <img
                src={rendsInit}
                alt="rends banner"
                className="single-img-banner"
              />
            </div>
          </div>
        </section>

        <section
          id="rends-init"
          className="container rends-sec rends-top-margin"
        >
          <h2>Before Design Phase</h2>
          <div className="rends__roll">
            <p>
              <br />
              In the initial stages of developing 'Rends,' the focus was on the
              essential groundwork before jumping into the design process. Right
              now, in the prototyping phase, testing out the twists and turns to
              make sure the ride is smooth.
            </p>

            <p>
              <br />
              Before everything, I took a moment to reflect on the idea. I set
              up a series of initial questions and dove into answering them. As
              a solo project, it was a bit like having a conversation with
              myself about the concept, challenging and refining it until it
              felt just right.
            </p>
            {/* <img
              src={question}
              alt="rends plan illustration"
              className="single-img"
            /> */}
            <img
              src={roadmap}
              alt="rends plan illustration"
              className="single-img"
            />
            <p>
              The site map emerged as a pivotal tool, outlining the blueprint of
              our online landscape. A distinctive feature of this map is its
              strategic division of components into two main branches:
              Consistent and Dynamic.
            </p>
            <p>
              The Consistent Components serve as the bedrock, providing a stable
              foundation for the platform. These elements ensure a uniform and
              cohesive user experience, forming the essential framework upon
              which 'Rends' stands. On the other hand, the Dynamic Components
              inject a sense of vitality, adapting to evolving trends and user
              interactions. They constitute the dynamic pulse of 'Rends,'
              ensuring the platform remains fresh and responsive to the dynamic
              nature of the online domain.
            </p>
            <p>
              In essence, the site map reflects a deliberate balance between
              stability and adaptability, designed to offer users a professional
              and straightforward experience within the evolving world of
              'Rends.'
            </p>
            <img
              src={sitemap}
              alt="rends plan illustration"
              className="single-img"
            />
          </div>
        </section>

        <section className="container rends-sec">
          <div className="threed">
            <h3>Design Phase: UIUX DESIGN</h3>
            <span style={{ color: "var(--primary)" }}>Wireframes</span>
            <br />
            <p>
              The wireframes aren't just about where buttons and images go; they
              are the visual manifestation of the vision, a sneak peek into the
              future look and feel of Rends'.
            </p>
            <p>
              These wireframes are reflection of the information gathered in the
              zero phase. As I sketched these wireframes, I had my eyes on the
              responsive too.
            </p>
            <p>
              Focusing on making sure the website would be easy for everyone to
              use. It's the foundation that sets the stage for the next steps in
              making 'Rends' a user-friendly and visually appealing digital
              space.
            </p>
            <RendsGallery photos={rendsWire} />
            <RendsGallery photos={rendsWireResponsive} />
          </div>
          <div className="threed">
            <span style={{ color: "var(--primary)" }} className="title-span">
              Hifi Components
            </span>
            <br />
            <p>
              Elevating 'Rends' from wireframes to high-fidelity design in Figma
              was a journey of creative transformation. Every component, from
              buttons to icons, became a visual language articulating the very
              essence of our brand. Starting with the wireframes as our rough
              sketches, we meticulously translated each element into a refined
              masterpiece. Iconography played a pivotal role as the silent
              narrator, guiding users through the digital narrative with
              consistency as our guiding principle.
            </p>
            <p>
              Beyond the creative process, crafting everything in Figma as
              components proved instrumental for project structure and future
              scalability. By designating repeatable elements as components, I
              not only streamlined the development process but also empowered
              adaptability. This approach facilitates efficient design
              changes—altering the main component initiates changes throughout
              the entire project. Such cohesiveness ensures not only ease in
              development but also scalability for future enhancements.
            </p>
            <RendsGallery photos={rendsComp} />
          </div>

          <div className="threed">
            <span style={{ color: "var(--primary)" }}>Hi Fidelity Design</span>
            <br />
            <p>
              The Auto Layout system seamlessly organized the components,
              ensuring responsive and visually consistent layouts across all
              pages. Simplicity is the key, translating into a straightforward
              and intuitive user experience. Navigation bars, content sections,
              and interactive elements are designed with clarity and purpose.
            </p>
            <p>
              This pragmatic approach to page design goes beyond aesthetics. It
              prioritizes user interaction, ensuring a user-centric experience
              whether accessed on a desktop or mobile device. The result is a
              collection of pages that embody the simplicity and solidness
              integral to 'Rends,' providing users with a dependable and
              engaging digital platform.
            </p>
            <RendsGallery photos={rendsHifi} />
          </div>

          <div className="threed">
            <span style={{ color: "var(--primary)" }}>
              Prototyping: Navigating the Desktop Experience
            </span>
            <br />
            <p>
              With the high-fidelity design foundation in place, the next stride
              in 'Rends' evolution was crafting a prototype for the desktop
              version. This involved seamlessly transitioning from static
              visuals to a dynamic user experience. Utilizing Figma's
              interactive features, each component came to life, offering a
              preview of how users would navigate and engage with the desktop
              interface.
            </p>
            <p>
              The prototype allows us to simulate the flow and functionality.
              This phase isn't just about aesthetics; it is a practical
              exploration of how users would move through the 'Rends' digital
              space.
            </p>
            <p>
              As we ventured into prototyping, the focus remained on
              user-centric design. The desktop prototype wasn't just a preview;
              it was a crucial step in refining the digital experience, aligning
              functionality with the expectations of 'Rends' users.
            </p>
            <iframe
              style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
              width="100%"
              height="450"
              src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FLOieqGueGlj7th6IaALMvW%2FRendMag-Design%3Ftype%3Ddesign%26node-id%3D103-1270%26t%3D2ddk3kbLUUj03GqH-1%26scaling%3Dmin-zoom%26page-id%3D0%253A1%26starting-point-node-id%3D103%253A1270%26mode%3Ddesign"
              allowFullScreen
              title="Figma Prototype"
            ></iframe>
          </div>
        </section>

        <section className="container rends-sec rends-top-margin">
          <div className="threed">
            <h3>What's Next</h3>
            <p>
              With the desktop prototype providing a tangible glimpse into the
              'Rends' digital landscape, the next phase unfurls with a focus on
              crafting responsive versions for smaller devices. This pivotal
              step involves translating the meticulously designed desktop
              interface into an equally engaging and user-friendly experience on
              smartphones and tablets. The goal is not only to adapt the layout
              but also to address any issues surfaced during the prototype
              phase, ensuring a seamless journey for users across diverse
              devices.
            </p>
            <p>
              The upcoming design iteration will delve into refining the user
              experience for compact screens, addressing navigation challenges,
              and optimizing visual elements for optimal display. This
              meticulous process aims to enhance accessibility and usability,
              aligning every interaction with the user-centric principles that
              guide 'Rends' design philosophy.
            </p>
            <p>
              Following the refinement of small device versions, the project is
              poised for the development stage. The groundwork laid in earlier
              steps, particularly the creation of a robust component library and
              the clean design work, sets the stage for a smooth transition into
              development. The component library, with its reusable and
              standardized elements, streamlines the development process,
              ensuring consistency and scalability. As we embark on the
              development journey, the collaborative efforts from design to
              prototyping pave the way for a cohesive and efficient execution,
              bringing 'Rends' to life with precision and reliability.
            </p>
            {/* <img
              src={rendsfin}
              alt="rends plan illustration"
              className="single-img"
            /> */}
          </div>
        </section>

        <section id="buts" className="container">
          <ButtonFullWidth
            buttonText="Go Back To Projects"
            linkTo="/Projects"
          />
        </section>
      </div>
    </div>
  );
}

export default Rends;
