import React from "react";
import { Link } from "react-router-dom";
import ScrollIndicator from "../helpers/ScrollIndicator";

import "./rezcult.css";

import { MdOutlineContactPage } from "react-icons/md";
import { MdOutlineArrowBack } from "react-icons/md";

import RezcultGallery from "../components/rezcult/RezcultGallery";
import ButtonFullWidth from "../components/ButtonFullWidth";
import { photos } from "../components/rezcult/RezcultGraphics";
import logoPresent from "../components/rezcult/rezcult-assets/rez-Logo-implementation-sheet.jpg";
import logoLayers from "../components/rezcult/rezcult-assets/rez-Logo-layers.jpg";
import logo from "../components/rezcult/rezcult-assets/rez-Logo.jpg";
import rezDesignSystem from "../components/rezcult/rezcult-assets/REZCULT-Design-System.jpg";
import rezSocialPresent from "../components/rezcult/rezcult-assets/rez-social-present.jpg";
import rezcultInit from "../components/rezcult/rezcult-assets/rezcult-intro.jpg";
import reelsvid1 from "../components/rezcult/rezcult-assets/rezcult-reels-1.mp4";
import reelsvid4 from "../components/rezcult/rezcult-assets/rezcult-reels-4.mp4";
import reelsvid6 from "../components/rezcult/rezcult-assets/rezcult-reels-6.mp4";


function Rezcult() {
  return (
    <div>
      <ScrollIndicator color="var(--primary)" />

      <div className="rezcult-page-container">
        <section id="rezcult-banner" className="rezcult">
          <div className="rezcult--banner">
            <div className="container rezcult__banner__content">
              <div>
                <h1>
                  Who Said You Can't Fly?
                  <br />
                  Just Do It!
                  <span className="span--font">
                    brand Re-designing case study
                  </span>
                </h1>
                <h2>Overview</h2>
                <p>
                  REZCULT is a boutique in Tehran that has embraced a fresh identity. Specializing in streetwear and sportswear, with a particular focus on Air Jordan sneakers, REZCULT is for those who appreciate the fusion of style and athleticism. The project focused on creating a brand identity that resonated with discerning clients, emphasizing quality and authenticity.
                </p>
              </div>
              <img
                src={rezcultInit}
                alt="rezcult banner"
                className="single-img-banner"
              />
            </div>
          </div>
        </section>

        <section id="rezcult-init" className="container rezcult-sec rez-top-margin">
          <h2>Project Structure and Methodology</h2>
          <div className="rezcult__roll">
            <p>
              <span style={{ color: "var(--primary)" }}>
                Logo Design
              </span>
              <br />
              The first step involved designing a new logo that would be the focal point of REZCULT's fresh identity. The logo needed to convey the essence of the brand, its love for streetwear, and, in particular, Air Jordan sneakers.
            </p>
            <p>
              <span style={{ color: "var(--primary)" }}>
                Design Guids
              </span>
              <br />
              To maintain a consistent look and feel across both digital and print graphics, we developed a comprehensive design guids. This encompassed color schemes, typography, imagery guidelines, and other visual elements that would be used across various marketing materials.
            </p>
            <p>
              <span style={{ color: "var(--primary)" }}>
                Social Media Posts
              </span>
              <br />
              We created a series of visuals for REZCULT's social media platforms, ensuring that each post and image adhered to the new design system. This consistency helped reinforce the brand's image and message in the minds of its followers.
            </p>
            <p>
              <span style={{ color: "var(--primary)" }}>
                Videos & Animation
              </span>
              <br />
              To add an extra layer of dynamism and engagement to REZCULT's online presence, we produced cool videos. These were not only used on social media but also in ads, effectively conveying the brand's message to a wider audience.
            </p>
            <p>
              Throughout these stages, we stayed true to REZCULT's target demographic and their overall approach to business. By understanding their preferences and values, we were able to create a rebrand that resonated with their tastes, emphasizing quality and authenticity. The subsequent sections of this case study will delve deeper into each aspect of the project, showcasing the thought process, design choices, and results achieved in this rebranding journey.
            </p>
          </div>
        </section>


        <section className="container rezcult-sec">
          <div className="threed">
            <img
              src={logo}
              alt="rezcult plan illustration"
              className="single-img"
            />
            <h3>Logo Design</h3>
            <p>
              A word mark was an essential component of the logo. Simultaneously, the client expressed a strong desire to retain the iconic Air Jordan Sneaker from their previous logo, making a pictorial element a necessity. These dual requirements pointed toward the creation of a combination type logo.
            </p>
            <p>
              Nevertheless, I aimed to breathe new life into the logo, infusing it with the feel of the business and a refreshed perspective. Consequently, I steered away from the conventional approach of placing a prominent Air Jordan illustration and simply type down 'REZCULT' below it. Instead, my intention was for the sneaker to be subtly integrated, allowing it to blend into the design, not immediately drawing attention upon first glance. Therefore, the logo needed supplementary elements to unify it into a cohesive and singular mark.
            </p>
            <img
              src={logoLayers}
              alt="rezcult plan illustration"
              className="single-img"
            />
            <p>
              The multi-element nature of the designed logo provides a distinct advantage – flexibility. By having multiple components that can be selectively omitted or emphasized, the logo accommodates different variations that can be strategically used as needed. This adaptability ensures that the logo can seamlessly integrate into a wide array of branding materials and promotional collateral while maintaining its core essence and impact.
            </p>
            <p>
              Furthermore, the logo was crafted to ensure its functionality across different scenarios, including dark mode, single-color applications, and black and white formats. This approach was taken to guarantee that the brand's identity remains consistent and recognizable, regardless of the medium or platform it is displayed on.
            </p>
            <img
              src={logoPresent}
              alt="rezcult plan illustration"
              className="single-img"
            />
          </div>
        </section>

        <section className="container rezcult-sec">
          <div className="threed">
            <h3>Design Guids</h3>
            <p>
              The dynamic design guids, built on principles of adaptability and creativity. We emphasize an adaptive color palette that forges a strong connection between visuals and our brand by aligning colors with each product's unique identity.
            </p>
            <p>
              The random grid system offers creative flexibility on social media, accommodating diverse elements, from product images to text and languages. Within each grid, a dominant element transcends boundaries and captivates our audience. This design guid also features two typography choices, one for English and one for Persian, ensuring a culturally sensitive and visually appealing approach.
            </p>
            <img
              src={rezDesignSystem}
              alt="rezcult plan illustration"
              className="single-img"
            />
          </div>
        </section>

        <section className="container rezcult-sec">
          <div className="threed">
            <h3>Social Media</h3>
            <p>
              In crafting social media posts for this project, we embraced our design guids' core principles. The random grid system, a hallmark of our approach, allowed for a dynamic and creative canvas. Within these grids, we combined a multitude of elements, from striking product images to vibrant text and graphics. This approach fosters an organic, unstructured visual aesthetic, engaging our audience's curiosity and imagination.
            </p>
            <p>
              What sets these posts apart is their unique blend of Western and Eastern influences. By deftly merging typography and imagery, we created a captivating synthesis of cultural flavors. The typefaces we employed, one for English and one for Persian, harmoniously coexist in the same visual space, enhancing the overall sense of fusion. The result is a series of social media posts that are not only visually arresting but also conceptually stimulating, inviting our audience to explore the dynamic world of REZCULT.
            </p>
            <img
              src={rezSocialPresent}
              alt="rezcult plan illustration"
              className="single-img"
            />
          </div>
          <RezcultGallery photos={photos} />
        </section>

        <section className="container rezcult-sec">
          <div className="threed">
            <h3>Videos & Animation</h3>
            <p>
              Creating videos for social media is all about bringing our unique design style to life. We maintained a consistent look and feel. With a dynamic grid system, we mix product visuals, engaging text, and captivating graphics to tell intriguing stories.
            </p>
            <p>
              Adding a touch of movement and energy, I use After Effects to create dynamic transitions and animations, making our videos even more exciting. Each video is a testament to the adaptability and commitment to visually stunning content that resonates with our diverse REZCULT audience.
            </p>
            <div className="vertical_vids_container">

              <video
                playsInline
                className="video--play-when-visible"
                id="video"
                src={reelsvid1}
                type="media/video/mp4"
                controls={true}
              />
              <video
                playsInline
                className="video--play-when-visible"
                id="video"
                src={reelsvid4}
                type="media/video/mp4"
                controls={true}
              />
              <video
                playsInline
                className="video--play-when-visible"
                id="video"
                src={reelsvid6}
                type="media/video/mp4"
                controls={true}
              />
            </div>
          </div>
        </section>

        <section
          id="rezcult-conclusion"
          className="container rezcult-sec rez-top-margin"
        >
          <h2>Conclusion</h2>
          <p>
            In wrapping up this project, we've embarked on a creative journey to redefine REZCULT's brand identity. With a distinctive look we've revitalized this boutique into a dynamic cultural hub, fostering a deeper connection with its discerning clientele. The design, featuring an adaptive color palette and a flexible grid system, is the backbone of our success, allowing us to effortlessly blend different elements into a captivating whole.
          </p>
        </section>

        <section id="buts" className="container">
          <ButtonFullWidth buttonText="Go Back To Projects" linkTo="/Projects" />
        </section>

      </div>
    </div>
  );
}

export default Rezcult;
