export const rendsComp = [
  {
    src: "../media/rends-comp/1.png",
    width: 5,
    height: 1,
  },
  {
    src: "../media/rends-comp/2.png",
    width: 4,
    height: 3,
  },
  {
    src: "../media/rends-comp/3.png",
    width: 3.2,
    height: 3,
  },
  {
    src: "../media/rends-comp/4.png",
    width: 4,
    height: 3,
  },
  // {
  //   src: "../media/rends-comp/5.png",
  //   width: 2,
  //   height: 1,
  // },
];
