export const renders = [
  {
    src: "../media/boratas-renders/1.jpg",
    width: 1.77,
    height: 1,
  },
  {
    src: "../media/boratas-renders/2.jpg",
    width: 1.77,
    height: 1,
  },
  {
    src: "../media/boratas-renders/3.jpg",
    width: 1.77,
    height: 1,
  },
];
