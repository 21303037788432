import React from "react";
import { Link } from "react-router-dom";

import "./about.css";
import mePic from "./about-assets/about-2.jpg";
import mePic2 from "./about-assets/about-3.jpg";
import mePic6 from "./about-assets/about-6.jpg";
import mePic8 from "./about-assets/me-gif - 3.gif";
import openEye from "./about-assets/open-01.svg";
import closeEye from "./about-assets/close-01.svg";

import ButtonWrappedWidth from "../ButtonWrappedWidth";
import ButtonFullWidth from "../ButtonFullWidth";

import FirstSong from "./FirstSong";
import SecondSong from "./SecondSong";

import { SiAdobephotoshop } from "react-icons/si";
import { SiAdobeillustrator } from "react-icons/si";
import { SiAdobeaftereffects } from "react-icons/si";
import { SiAdobelightroom } from "react-icons/si";
import { SiAdobeindesign } from "react-icons/si";
import { SiAdobepremierepro } from "react-icons/si";
import { SiBlender } from "react-icons/si";
import { SiAutodesk } from "react-icons/si";
import { SiGit } from "react-icons/si";
import { SiReact } from "react-icons/si";
import { SiJavascript } from "react-icons/si";
import { SiHtml5 } from "react-icons/si";
import { SiCss3 } from "react-icons/si";
import { SiFigma } from "react-icons/si";
import { SiAbletonlive } from "react-icons/si";
import { SiAdobeaudition } from "react-icons/si";

import { BiCamera } from "react-icons/bi";
import { TbDrone } from "react-icons/tb";
import { useState } from "react";

export default function About() {
  const [eyes, setEyes] = useState(openEye);

  return (
    <section id="about" className="about">
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={mePic8} alt="me-about" />
          </div>
        </div>
        <div className="about__content">
          <p>
            During my time as an architecture student, I landed a position at an
            architecture studio where my role involved creating visual designs
            that aided communication between the architects and clients. This
            sparked my passion for multimedia design, and as my career
            progressed, I took on an array of diverse projects.
          </p>
          <p>
            Over the past six years, I've been Committed to growth and ventured
            into coding and web design. Currently, my focus is on enhancing
            design proficiency and exploring 3D animation—an expression of my
            dedication to pushing creative boundaries in the ever-evolving
            landscape of design.
          </p>
          <section id="buts" className="container">
            <ButtonWrappedWidth buttonText="Let's Talk" linkTo="/Contact" />
          </section>
        </div>
      </div>

      <div className="container skills__container">
        <h3 className="skills__title">My Digital Skills</h3>
        <div className="skills__content">
          <div className="skills__block">
            <h5 className="skills__type">Visual</h5>
            <div className="skills__tags">
              <ul className="skills__tags_tags">
                <li>Graphic Design</li>
                <li>Illustration</li>
                <li>Motion Graphics</li>
                <li>Animation</li>
                <li>Video Editing</li>
                <li>Branding</li>
                <li>Digital & Print Design</li>
                <li>3D</li>
                <li>Drone/Camera Operating</li>
              </ul>
            </div>
          </div>
          <div className="skills__block">
            <h5 className="skills__type">Web</h5>
            <div className="skills__tags">
              <ul className="skills__tags_tags">
                <li>HTML</li>
                <li>CSS</li>
                <li>JavaScript</li>
                <li>ReactJS</li>
                <li>UI Design</li>
                <li>Interaction design</li>
                <li>Frontend</li>
                <li>Creative Coding</li>
                <li>SEO</li>
              </ul>
            </div>
          </div>
          <div className="skills__block">
            <h5 className="skills__type">Audio</h5>
            <div className="skills__tags">
              <ul className="skills__tags_tags">
                <li>Sound Design</li>
                <li>Recording</li>
                <li>Mix & Master</li>
                <li>Musical Sounds</li>
                <li>AI Voice Overing</li>
                <li>Digital & Analog</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container tools__container">
        <div className="tools__content">
          <h3 className="tools__title">My Fav. Tools</h3>
          <div className="tools__block">
            <div className="tools__tools">
              <div className="tools__item">
                <SiAdobephotoshop className="tools__item__icon" />
                <span>Photoshop</span>
              </div>
              <div className="tools__item">
                <SiAdobeillustrator className="tools__item__icon" />
                <span>Illustrator</span>
              </div>
              <div className="tools__item">
                <SiAdobelightroom className="tools__item__icon" />
                <span>Lightroom</span>
              </div>
              <div className="tools__item">
                <SiAdobeindesign className="tools__item__icon" />
                <span>Indesign</span>
              </div>
              <div className="tools__item">
                <SiAdobeaftereffects className="tools__item__icon" />
                <span>After Effects</span>
              </div>
              <div className="tools__item">
                <SiAdobepremierepro className="tools__item__icon" />
                <span>Premiere</span>
              </div>
              <div className="tools__item">
                <SiAdobeaudition className="tools__item__icon" />
                <span>Audition</span>
              </div>
              <div className="tools__item">
                <SiAbletonlive className="tools__item__icon" />
                <span>Ableton</span>
              </div>
              <div className="tools__item">
                <SiBlender className="tools__item__icon" />
                <span>Blender</span>
              </div>
              <div className="tools__item">
                <SiAutodesk className="tools__item__icon" />
                <span>Autodesk</span>
              </div>
              <div className="tools__item">
                <SiFigma className="tools__item__icon" />
                <span>Figma</span>
              </div>
              <div className="tools__item">
                <SiGit className="tools__item__icon" />
                <span>Git</span>
              </div>
              <div className="tools__item">
                <SiHtml5 className="tools__item__icon" />
                <span>HTML</span>
              </div>
              <div className="tools__item">
                <SiCss3 className="tools__item__icon" />
                <span>CSS</span>
              </div>
              <div className="tools__item">
                <SiJavascript className="tools__item__icon" />
                <span>JavaScript</span>
              </div>
              <div className="tools__item">
                <SiReact className="tools__item__icon" />
                <span>ReactJS</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about__more">
        <span className="span--font">wanna know me more?</span>
      </div>

      <div className="container music__container">
        <div className="music__content">
          <h3 className="hobby__title">Grooving through life with music</h3>
          <p>
            It's not just something I enjoy listening to, but also a creative
            outlet that brings me immense joy. I play a few instruments and love
            composing songs in my free time. It's a hobby that truly lights me
            up.
          </p>
          <p>
            I also have a passion for performing music at events and venues.
            There's something incredibly special about connecting with a crowd
            through music. These experiences have also had a profound impact on
            my career as a multimedia designer, as I often work with sound and
            music elements in the projects. It's a beautiful intersection of my
            creative pursuits.
          </p>
          <p>Check out a couple of pieces, I hope you enjoy ;)</p>
          <FirstSong />
          <SecondSong />
        </div>
        <div className="music__me">
          <div className="music__me-image">
            <img src={mePic2} alt="me" />
          </div>
          <div className="music__me-image">
            <img src={mePic6} alt="me" className="twin-pic" />
          </div>
        </div>
      </div>

      <div className="container fly__container">
        <div className="fly__me">
          <div className="fly__me-image">
            <img src={mePic} alt="me" />
          </div>
        </div>
        <div className="fly__content">
          <h3 className="hobby__title">When Boredom Takes Off, I Fly!</h3>
          <p>
            My DJI Drone is the epitome of coolness! Taking it for a spin and
            exploring new locations, it's just so cool!
          </p>
          <p>
            While the thrill of flying is undeniably captivating, what truly
            fascinates me is the world of aerial photography & videography. It
            adds an intriguing dimension to my drone flying hobby as I harness
            the breathtaking aerial visuals as a source of inspiration for my
            design work. For me, flying is not just a pastime; it's a practical
            pursuit that complements my career aspirations. I've curated a
            collection of my non-professional photographs, which I invite you to
            explore through the link provided below.
          </p>

          {/* <Link to={"/photo-collection"} className="btn btn-primary">
            <BiCamera className="btn-icon" />
            <TbDrone className="btn-icon" />
            <br />
            My photograph collection
          </Link> */}
          <section id="buts" className="container">
            <ButtonWrappedWidth
              buttonText="Check Out My Photograph Collection"
              linkTo="/Photo-Collection"
            />
          </section>
        </div>
      </div>

      <div className="container funny__container">
        <div className="funny__content">
          <h3 className="hobby__title">Sometimes I Just Close My Eyes!</h3>
          <p>
            If you see me sitting still with my eyes closed, it doesn't mean I'm
            sleeping.
            <br />
            In moments when my mind feels overwhelmed and needs a reset, I turn
            to a simple yet powerful technique. Taking just 4-7 minutes, this
            method significantly enhances my mental clarity and concentration. I
            fondly call it my "performance-boosting system."
          </p>
        </div>
        <div className="about__me" style={{ backgroundColor: "transparent" }}>
          <div
            className="about__me-image"
            onMouseEnter={() => setEyes(closeEye)}
            onMouseLeave={() => setEyes(openEye)}
          >
            <img src={eyes} alt="me" />
          </div>
        </div>
      </div>

      <div className="container last__container">
        <h3 className="hobby__title">Last But Not Least</h3>
        <div className="last__content">
          <div className="last__block">
            <div className="last__tags">
              <ul className="last__tags_tags">
                <li>Good Food</li>
                <li>Good Friends</li>
                <li>Breeze</li>
                <li>Smell of Summer Grass</li>
                <li>Summer</li>
                <li>Sounds of outdoor dining</li>
                <li>Pesto Sause</li>
                <li>All Cats</li>
                <li>Kind Dogs</li>
                <li>Weekend Cleaning</li>
                <li>Heavy Duty Equipment</li>
                <li>New Devices</li>
                <li>Spotify</li>
                <li>Golden Hours</li>
                <li>Early Evenings</li>
                <li>Neon Lights</li>
                <li>After-Rain Fresh Air</li>
                <li>Drama-Comedy</li>
                <li>Sci-fi</li>
                <li>Lofi</li>
                <li>...</li>
                <li>Life</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
