export const rendsWireResponsive = [
  {
    src: "../media/rends-wireframes/2.png",
    width: 4,
    height: 2,
  },
  {
    src: "../media/rends-wireframes/3.png",
    width: 1,
    height: 1,
  },
];
