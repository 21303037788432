import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaArrowRight } from "react-icons/fa";

const StyledExperienceBlock = styled.div`
  max-width: var(--max-width-desktop);
  margin: 0 64px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: max-content;
  padding: 24px;
  padding-left: 32px;
  width: calc(100% - 32px);

  @media screen and (max-width: 1024px) {
    width: var(--max-width-medium);
    padding-left: 24px;
  }

  @media screen and (max-width: 600px) {
    width: var(--max-width-small);
    padding: 8px;
    border-radius: 8px;
  }
`;

const StyledExperienceBlockContent = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const StyledExperienceBlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: center;
  height: 100%;
  max-height: 100%;
  width: 45%;
  justify-self: flex-start;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledH3 = styled.h3`
  font-size: 34px;
  line-height: 32px;
  color: var(--primary);
  font-weight: 500;

  @media screen and (max-width: 600px) {
    font-size: 28px;
    line-height: 32px;
  }
`;

const StyledPosition = styled.span`
  font-size: 18px;
  color: rgb(88, 88, 88);

  @media screen and (max-width: 600px) {
    line-height: 16px;
  }
`;

const StyledInfoDescription = styled.p`
  color: rgb(75, 75, 75);
`;

const StyledTags = styled.ul`
  list-style-type: none;
  margin-top: 8px;
  padding: 0;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const StyledTag = styled.li`
  font-size: 16px;
  float: left;
  display: block;
  color: white;
  background-color: var(--color-black);
  text-align: center;
  padding: 4px 14px;
  margin: 0px 10px 10px 0px;
  text-decoration: none;
  border-radius: 8px;
`;

const StyledExperienceBlockInfoBut = styled(Link)`
  margin-top: 8px;
  color: var(--primary);
  line-height: normal;
  display: flex;
  gap: 16px;
  transition: var(--transition);

  @media screen and (max-width: 1024px) {
    padding-bottom: 16px;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }

  &:hover {
    margin-left: 3.2px;
    transition: var(--transition);
  }

  &:hover > .arrow {
    margin-left: 16px;
    transition: var(--transition);
  }
`;

const StyledArrow = styled(FaArrowRight)`
  transition: var(--transition);
  font-size: 20px;
  align-self: center;
`;

const StyledExperienceBlockMedia = styled.div`
  height: 100%;
  width: 55%;
  justify-self: right;
  align-self: center;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 1024px) {
    height: auto;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    margin-top: 8px;
  }
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    border-radius: 4px;
  }
`;

const ExperienceBlock = (props) => {
  const { title, position, description, tags, url, videoSrc } = props;

  const myRef = useRef(null);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    let observer;

    if (myRef.current) {
      observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        if (entry && entry.target && myRef.current) {
          setIsVideoVisible(entry.isIntersecting);
        }
      });

      observer.observe(myRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }

      // Check if myRef.current is not null before pausing the video
      if (myRef.current) {
        myRef.current.pause();
      }
    };
  }, []);

  useEffect(() => {
    // Check if myRef.current is not null and the component is mounted
    if (myRef.current) {
      try {
        if (isVideoVisible && !isVideoPlaying) {
          myRef.current.play();
          setIsVideoPlaying(true);
        } else if (!isVideoVisible && isVideoPlaying) {
          myRef.current.pause();
          setIsVideoPlaying(false);
        }
      } catch (error) {
        // Handle play() promise rejection (e.g., autoplay policy)
        console.error("Failed to play/pause video:", error);
      }
    }
  }, [isVideoVisible, isVideoPlaying]);

  return (
    <StyledExperienceBlock>
      <StyledExperienceBlockContent>
        <StyledExperienceBlockInfo>
          <div>
            <StyledH3>{title}</StyledH3>
            <StyledPosition>{position}</StyledPosition>
            <div style={{ fontSize: "16px", color: "var(--primary-alt)" }}>
              {props.date}
            </div>
          </div>
          <StyledInfoDescription>{description}</StyledInfoDescription>
          <StyledTags>
            {tags.map((tag, i) => (
              <StyledTag key={i}>{tag}</StyledTag>
            ))}
          </StyledTags>
          <StyledExperienceBlockInfoBut to={`${url}`}>
            Explore Case Study <StyledArrow className="arrow" />
          </StyledExperienceBlockInfoBut>
        </StyledExperienceBlockInfo>
        <StyledExperienceBlockMedia>
          <Link to={`${url}`}>
            <StyledVideo
              playsInline
              muted
              loop
              className="video--play-when-visible"
              id="video"
              ref={myRef}
              src={videoSrc}
              type="media/video/mp4"
            />
          </Link>
        </StyledExperienceBlockMedia>
      </StyledExperienceBlockContent>
    </StyledExperienceBlock>
  );
};

export default ExperienceBlock;
