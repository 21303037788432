import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { MdOutlineEmail, MdOutlineMarkEmailRead } from "react-icons/md";

const HeroInfoButs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 32px;

  @media screen and (max-width: 600px) {
    padding-top: 16px;
  }
`;

const Button = styled.button`
  background: #3d4c53;
  width: 100%;
  height: 64px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  transition: 0.2s;
  cursor: pointer;
  border-radius: 4.8px;
  border: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  font-size: 19.2px;

  .btn-icon {
    margin-right: 16px;
  }

  .btnTwo {
    position: relative;
    width: 30%;
    height: 100%;
    text-align: center;
    background: var(--primary);
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: -31%;
  }

  &:hover .btnTwo {
    left: -10px;
  }

  &:hover .btnText {
    left: -10px;
  }

  &:active {
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.3);
  }
`;

const BtnText = styled.p`
  position: relative;
  width: 70%;
  color: white;
  transition: 0.3s;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  left: -15%;
  font-size: 19.2px;

  .btn-icon {
    margin-right: 16px;
  }
`;

const BtnText2 = styled.p`
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

function CTA() {
  const emailAdress = "koushayeganeh@gmail.com";

  const [copyText, setCopyText] = useState("Click to copy");
  const [emailIcon, setEmailIcon] = useState(
    <MdOutlineEmail className="btn-icon" />
  );

  function copyEmail() {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(emailAdress)
        .then(() => {
          setCopyText("Copied!");
          setEmailIcon(<MdOutlineMarkEmailRead className="btn-icon" />);
        })
        .catch((error) => {
          console.error("Unable to copy to clipboard", error);
          setCopyText("Error copying");
        });
    } else {
      // Fallback for browsers that don't support Clipboard API on mobile
      try {
        const textArea = document.createElement("textarea");
        textArea.value = emailAdress;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);

        setCopyText("Copied!");
        setEmailIcon(<MdOutlineMarkEmailRead className="btn-icon" />);
      } catch (error) {
        console.error("Unable to copy to clipboard", error);
        setCopyText("Error copying");
      }
    }
  }

  function resetText() {
    setCopyText("Click to Copy");
    setEmailIcon(<MdOutlineEmail className="btn-icon" />);
  }

  return (
    <HeroInfoButs>
      <Button onClick={copyEmail} onMouseLeave={resetText}>
        <div className="btnTwo">
          <BtnText2>{copyText}</BtnText2>
        </div>
        <BtnText className="btnText">
          {emailIcon}
          koushayeganeh@gmail.com
        </BtnText>
      </Button>
    </HeroInfoButs>
  );
}

export default CTA;
