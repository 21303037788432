export const socials = [
  // {
  //   src: "../media/boratas-socials/1.jpg",
  //   width: 4,
  //   height: 3,
  // },
  {
    src: "../media/boratas-socials/2.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "../media/boratas-socials/3.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "../media/boratas-socials/4.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "../media/boratas-socials/5.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "../media/boratas-socials/6.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "../media/boratas-socials/7.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "../media/boratas-socials/8.jpg",
    width: 1,
    height: 1,
  },
  {
    src: "../media/boratas-socials/9.jpg",
    width: 1,
    height: 1,
  },
];
