export const prints = [
  {
    src: "../media/boratas-prints/1.jpg",
    width: 1.4,
    height: 1,
  },
  {
    src: "../media/boratas-prints/2.jpg",
    width: 1.4,
    height: 1,
  },
  {
    src: "../media/boratas-prints/3.jpg",
    width: 1.4,
    height: 1,
  },
  {
    src: "../media/boratas-prints/4.jpg",
    width: 1.4,
    height: 1,
  },
  {
    src: "../media/boratas-prints/5.jpg",
    width: 1.4,
    height: 1,
  },
  {
    src: "../media/boratas-prints/6.jpg",
    width: 1.4,
    height: 1,
  },
  {
    src: "../media/boratas-prints/7.jpg",
    width: 1.4,
    height: 1,
  },
];
