import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import FeaturedBlock from "./FeaturedBlock";
import FeaturedBlockType2 from "./FeaturedBlockType2";
import "./featured.css";

import bluishLemons from "./featured-assets/thumbnails/bluish-lemons-f.webp";
import colaRing from "./featured-assets/thumbnails/cola-ring-f.webp";
import coolCat from "./featured-assets/thumbnails/cool-cat-f.webp";
import differences from "./featured-assets/thumbnails/differences-f.webp";
import dude from "./featured-assets/thumbnails/dude-f.webp";
import tak from "./featured-assets/thumbnails/tak-logo.jpg";
import neon from "./featured-assets/thumbnails/neon-f.webp";
import pengs from "./featured-assets/thumbnails/pengs-f.webp";
import sarahLynn from "./featured-assets/thumbnails/sarah-lynn-f.webp";
import sit from "./featured-assets/thumbnails/sit-f.jpg";
import p311 from "./featured-assets/thumbnails/311-1.1.jpg";
import sigma from "./featured-assets/thumbnails/sigma-preview.jpg";
import p1 from "./featured-assets/thumbnails/p1-preview.jpg";
import p7 from "./featured-assets/thumbnails/p7-preview.jpg";
import present from "./featured-assets/thumbnails/present-m-f.mp4";
import redCd from "./featured-assets/thumbnails/redCd-preview.mp4";
import cruart from "./featured-assets/thumbnails/Cruart-preview.mp4";
import toiletPaper from "./featured-assets/thumbnails/TP-preview.mp4";
import explode from "./featured-assets/thumbnails/explode-m-f.mp4";
import izmir100 from "./featured-assets/thumbnails/izmir100_preview.mp4";
import number9 from "./featured-assets/thumbnails/number9-preview.mp4";
import rwin from "./featured-assets/thumbnails/rwin-preview.mp4";
import ball from "./featured-assets/thumbnails/ball-preview.mp4";

export default function Featured() {
  const motionPresentExData = {
    tags: ["Motion", "Aftereffects"],
    videoSrc: present,
  };

  const motionRedCdExData = {
    tags: ["Motion", "Aftereffects"],
    videoSrc: redCd,
    path: "red-cd",
  };

  const motionToiletPaperData = {
    tags: ["Motion", "Aftereffects"],
    videoSrc: toiletPaper,
    path: "toilet-paper",
  };

  const motionIzmir100Data = {
    tags: ["Motion", "Aftereffects"],
    videoSrc: izmir100,
    path: "izmir-100music",
  };

  const motionNumber9Data = {
    tags: ["Digital Painting", "Personal"],
    videoSrc: number9,
    path: "number9",
  };

  const motionCruartData = {
    tags: ["Motion", "Aftereffects"],
    videoSrc: cruart,
    path: "cruart",
  };

  const motionExplodeExData = {
    tags: ["Motion", "Aftereffects"],
    videoSrc: explode,
  };

  const rwinExData = {
    tags: ["Motion", "Aftereffects"],
    videoSrc: rwin,
  };

  const ballExData = {
    tags: ["Motion", "Aftereffects"],
    videoSrc: ball,
  };

  const pengsExData = {
    tags: ["Illustration", "Poster"],
    imgSrc: pengs,
    imgAlt: "Sahra Pengs",
    path: "penguins-can-not-fly",
  };

  const p311ExData = {
    tags: ["Digital Paint", "Concept Design"],
    imgSrc: p311,
    imgAlt: "311 concept design",
    path: "NO311",
  };

  const sigmaExData = {
    tags: ["Digital Paint", "Concept Design"],
    imgSrc: sigma,
    imgAlt: "sigma",
    path: "NO311",
  };

  const p1ExData = {
    tags: ["AI Generative", "Experimental"],
    imgSrc: p1,
    imgAlt: "AI experiment",
    path: "ai-oKeeffe",
  };

  const p7ExData = {
    tags: ["AI Generative", "Experimental"],
    imgSrc: p7,
    imgAlt: "AI experiment",
    path: "ai-tiny-body-parts",
  };

  const bluishLemonsExData = {
    tags: ["Graphic", "Cover"],
    imgSrc: bluishLemons,
    imgAlt: "bluish lemons",
    path: "bluish-lemons",
  };

  const colaRingExData = {
    tags: ["Graphic", "Cover"],
    imgSrc: colaRing,
    imgAlt: "cola ring",
    path: "saturn-cafe",
  };

  const coolCatExData = {
    tags: ["Graphic", "Cover"],
    imgSrc: coolCat,
    imgAlt: "cool cat",
    path: "cool-cat",
  };

  const differencesExData = {
    tags: ["Illustration", "Volunteer"],
    imgSrc: differences,
    imgAlt: "differences",
    path: "embracing-our-differences",
  };

  const dudeExData = {
    tags: ["Rigged Character"],
    imgSrc: dude,
    imgAlt: "dude",
    path: "dude",
  };

  const takExData = {
    tags: ["logo design"],
    imgSrc: tak,
    imgAlt: "tak-logo",
    path: "tak-logo",
  };

  const neonExData = {
    tags: ["Graphic", "Cover"],
    imgSrc: neon,
    imgAlt: "neon",
    path: "neon-moon",
  };

  const sarahLynnExData = {
    tags: ["Rigged Character", "Adobe Animate"],
    imgSrc: sarahLynn,
    imgAlt: "sarah lynn",
    path: "sarah-lynn",
  };

  const sitExData = {
    tags: ["Graphic", "Cover"],
    imgSrc: sit,
    imgAlt: "sit",
    path: "equipoise",
  };

  return (
    <section id="featured" className="featured">
      <div className="container featured__container">
        <p
          style={{ color: "white", paddingBottom: "16px", paddingLeft: "16px" }}
        >
          The cards tagged with 🔗 are clickable. Click to explore more.
        </p>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 2, 600: 2, 1024: 3, 1400: 4 }}
        >
          <Masonry>
            <FeaturedBlockType2
              tags={takExData.tags}
              imgSrc={takExData.imgSrc}
              imgAlt={takExData.imgAlt}
              path={takExData.path}
              isClickable={true}
            />

            <FeaturedBlock
              tags={motionCruartData.tags}
              videoSrc={motionCruartData.videoSrc}
              path={motionCruartData.path}
              isClickable={true}
            />

            <FeaturedBlockType2
              tags={pengsExData.tags}
              imgSrc={pengsExData.imgSrc}
              imgAlt={pengsExData.imgAlt}
              path={pengsExData.path}
            />

            <FeaturedBlockType2
              tags={p311ExData.tags}
              imgSrc={p311ExData.imgSrc}
              imgAlt={p311ExData.imgAlt}
              path={p311ExData.path}
            />

            <FeaturedBlock
              tags={motionRedCdExData.tags}
              videoSrc={motionRedCdExData.videoSrc}
              path={motionRedCdExData.path}
              isClickable={true}
            />

            <FeaturedBlockType2
              tags={bluishLemonsExData.tags}
              imgSrc={bluishLemonsExData.imgSrc}
              imgAlt={bluishLemonsExData.imgAlt}
              path={bluishLemonsExData.path}
            />

            <FeaturedBlock
              tags={motionExplodeExData.tags}
              videoSrc={motionExplodeExData.videoSrc}
              path={motionExplodeExData.path}
            />

            <FeaturedBlockType2
              tags={sigmaExData.tags}
              imgSrc={sigmaExData.imgSrc}
              imgAlt={sigmaExData.imgAlt}
              path={sigmaExData.path}
            />

            <FeaturedBlock
              tags={ballExData.tags}
              videoSrc={ballExData.videoSrc}
              path={ballExData.path}
            />

            <FeaturedBlockType2
              tags={p1ExData.tags}
              imgSrc={p1ExData.imgSrc}
              imgAlt={p1ExData.imgAlt}
              path={p1ExData.path}
              isClickable={true}
            />

            <FeaturedBlockType2
              tags={p7ExData.tags}
              imgSrc={p7ExData.imgSrc}
              imgAlt={p7ExData.imgAlt}
              path={p7ExData.path}
              isClickable={true}
            />

            <FeaturedBlockType2
              tags={colaRingExData.tags}
              imgSrc={colaRingExData.imgSrc}
              imgAlt={colaRingExData.imgAlt}
              path={colaRingExData.path}
            />

            <FeaturedBlock
              tags={motionNumber9Data.tags}
              videoSrc={motionNumber9Data.videoSrc}
              path={motionNumber9Data.path}
              isClickable={true}
            />

            <FeaturedBlock
              tags={motionPresentExData.tags}
              videoSrc={motionPresentExData.videoSrc}
              path={motionPresentExData.path}
            />

            <FeaturedBlock
              tags={motionIzmir100Data.tags}
              videoSrc={motionIzmir100Data.videoSrc}
              path={motionIzmir100Data.path}
              isClickable={true}
            />

            <FeaturedBlock
              tags={motionToiletPaperData.tags}
              videoSrc={motionToiletPaperData.videoSrc}
              path={motionToiletPaperData.path}
              isClickable={true}
            />

            <FeaturedBlockType2
              tags={dudeExData.tags}
              imgSrc={dudeExData.imgSrc}
              imgAlt={dudeExData.imgAlt}
              path={dudeExData.path}
            />

            <FeaturedBlockType2
              tags={differencesExData.tags}
              imgSrc={differencesExData.imgSrc}
              imgAlt={differencesExData.imgAlt}
              path={differencesExData.path}
            />

            <FeaturedBlock
              tags={rwinExData.tags}
              videoSrc={rwinExData.videoSrc}
              path={rwinExData.path}
            />

            <FeaturedBlockType2
              tags={neonExData.tags}
              imgSrc={neonExData.imgSrc}
              imgAlt={neonExData.imgAlt}
              path={neonExData.path}
            />

            <FeaturedBlockType2
              tags={coolCatExData.tags}
              imgSrc={coolCatExData.imgSrc}
              imgAlt={coolCatExData.imgAlt}
              path={coolCatExData.path}
            />

            <FeaturedBlockType2
              tags={sitExData.tags}
              imgSrc={sitExData.imgSrc}
              imgAlt={sitExData.imgAlt}
              path={sitExData.path}
            />

            {/* <FeaturedBlockType2
              tags={planExData.tags}
              imgSrc={planExData.imgSrc}
              imgAlt={planExData.imgAlt}
              path={planExData.path}
            /> */}

            {/* <FeaturedBlockType2
              tags={sarahLynnExData.tags}
              imgSrc={sarahLynnExData.imgSrc}
              imgAlt={sarahLynnExData.imgAlt}
              path={sarahLynnExData.path}
            /> */}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </section>
  );
}
