import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

    body {
      margin: 0; line-height: normal;
      background: #121512;
      font-family: 'poppins';
    }

    a {
      text-decoration: none;
    }

    
      h3 {
        color: var(--primary);
        margin: 0;
        padding: 0;
      }

      .btn {
        width: max-content;
        display: inline-block;
        color: var(--primary);
        padding: 12px 28px;
        border-radius: 8px;
        cursor: pointer;
        border: 1px solid var(--primary);
        transition: var(--transition);
      }
      
      .btn:hover {
        background: var(--color-white);
        color: var(--primary);
        border-color: transparent;
      }
      
      .btn-primary {
        background: var(--primary);
        color: var(--color-white);
        text-align: center;
      }
      
      .btn-icon {
        font-size: 28px;
        vertical-align: bottom;
      }
      
    
:root {

/* fonts */
--buttons-text: Poppins;

/* font sizes */
--body-text-size: 16px;
--heading-size: 32px;
--buttons-text-size: 18px;
--display-text-size: 48px;

/* Colors */
--white: #fcfcfc;
--dark-background: #121512;
--color-red: #ff0000;
--color-white: #f0f0f0;
--primary: #26a69a;
--color-dimgray: #545454;
--color-black: #000;
--primary-alt: #93b6b0;

/* Gaps */
--gap-3xs: 10px;
--gap-base: 16px;
--gap-5xl: 24px;
--gap-45xl: 64px;
--gap-5xs: 8px;
--gap-37xl: 56px;

/* Paddings */
--padding-141xl: 64px;
--padding-170xl: 189px;
--padding-280xl: 299px;
--padding-mini-5: 14.5px;
--padding-xs: 12px;
--padding-base: 16px;
--padding-5xl: 24px;
--padding-45xl: 64px;

/* Border radiuses */
--br-71xl: 90px;
--br-5xs: 8px;


/* Max Width and Min Width */
--max-width-desktop : 1260px;
--max-width-medium : calc(100% - 32px);
--max-width-small : calc(100% - 32px);

--transition: all 400ms ease;


}
`;
