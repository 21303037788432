import React from "react";
import { Link } from "react-router-dom";
import ScrollIndicator from "../helpers/ScrollIndicator";
import ThreeSixty from "react-360-view";

import "./boratas.css";

import { MdOutlineContactPage } from "react-icons/md";
import { MdOutlineArrowBack } from "react-icons/md";

import YoutubeEmbed from "../helpers/YoutubeEmbed";
import BoratasGallery from "../components/boratas/BoratasGallery";
import { photos } from "../components/boratas/BoratasPhotos";
import { graphics } from "../components/boratas/BoratasGraphics";
import { socials } from "../components/boratas/BoratasSocials";
import { prints } from "../components/boratas/BoratasPrints";
import { renders } from "../components/boratas/BoratasRenders";
import rendersCompare from "../components/boratas/boratas-assets/renders-compare-webp.webp";
import productTree from "../components/boratas/boratas-assets/product-tree.jpg";
import boratasInit from "../components/boratas/boratas-assets/boratas-intro-webp.webp";
import boratasWeb from "../components/boratas/boratas-assets/boratas-web-webp.webp";

import BoratasSlider from "../components/boratas/BoratasSlider";
import ButtonFullWidth from "../components/ButtonFullWidth";
import { useEffect } from "react";

function Boratas() {
  useEffect(() => {
    const container = document.getElementById("container360");
    container.addEventListener("wheel", (event) => {
      event.stopPropagation();
    });
    return () => {
      container.removeEventListener("wheel", (event) => {
        event.stopPropagation();
      });
    };
  }, []);

  return (
    <div>
      <ScrollIndicator color="var(--primary)" />

      <div className="boratas-page-container">
        <section id="boratas-banner" className="boratas">
          <div className="boratas--banner">
            <div className="container boratas__banner__content">
              <div>
                <h1>
                  From a Domestic Company, <br /> to an International Brand.
                  <span className="span--font">
                    multimedia designing case study
                  </span>
                </h1>
                <h3>Overview</h3>
                <p>
                  Boratas is a mining machinery producer based in Turkey. Having
                  exported to more than 20 countries in 10 years, Boratas is at
                  the stage of becoming an international brand. Therefore, the
                  company is aware of the importance of branding for entering
                  the worldwide network and this is where I started my job at
                  this company.
                </p>
              </div>
              <img
                src={boratasInit}
                alt="boratas banner"
                className="single-img-banner"
              />
            </div>
          </div>
        </section>

        <section id="boratas-roll" className="container boratas-sec">
          <h2>My Roll</h2>
          <div className="boratas__roll">
            <p>
              My responsibility in Boratas has been helping the company to
              create an outstanding image for use in multiple channels while
              showing the company's personality as well as the products'
              features. I've been doing this in three steps according to the
              company's requirements.
            </p>
            <p>
              <span style={{ color: "var(--primary-alt)" }}>
                Step 1 : Visual Content Creation
              </span>
              <br />I started by creating an organized archive including photos,
              videos, illustrations, graphic works, and other needed visuals for
              use in the next steps.
            </p>
            <p>
              <span style={{ color: "var(--primary-alt)" }}>
                Step 2 : Brand Design
              </span>
              <br />
              After preparing images, it was time to add a corporate look to
              everything by applying a unique color grade and making a stylish
              look. Afterward, I started to design flyers, catalogs, manuals,
              etc.
            </p>
            <p>
              At the same time, we started to create informative videos and
              posts for social media channels. With the help of co-workers in
              the sales department, we created a strategy for our social media
              channels. Over time we increased posting frequency and diversity
              according to that strategy.
            </p>
            <p>
              <span style={{ color: "var(--primary-alt)" }}>
                Step 3 : Web Design
              </span>
              <br />
              After having done the first two steps, it was the right time to
              redesign the company's website using the new materials we had
              gathered; On the other hand, according to Google Analytics over
              80% of the website visitors were coming via links in social media
              channels, which shows the success of our social media strategies.
              Still, at the same time, it was dissatisfaction for visitors
              because there was a noticeable difference in the quality of shared
              images on social media compared to the ones on the website.
            </p>
          </div>
        </section>

        <section id="boratas-problem" className="container boratas-sec">
          <h2>The Problem</h2>
          <div className="boratas__problem">
            <p>
              Boratas is a young company run by an ambitious crew. The passion
              of the team in developing the products and exploring new
              technologies opened the company's way to international markets
              while there are stable and well-known brands to compete with.
            </p>
            <p>
              No doubt it's a brilliant achievement, But the problem was during
              recent years, the company's focus had been mostly on producing and
              selling, without creating any visual-based content. While day by
              day brands and companies spend more and more resources on their
              appearance on the internet. So basically the company was expanding
              but not getting more orders as expected.
            </p>
            <p>
              When I started my job in Boratas there wasn't any categorized
              archive for photos and videos. The existing images were unedited
              and unprofessional. There was almost no strategy for social media,
              and the shared contents were random and low in quality.
            </p>
            <p>
              Also, the company had two websites, but both of them were outdated
              and full of bugs. There were products and services which no images
              were found for them.
            </p>
          </div>
        </section>

        <section id="boratas-works" className="container boratas-sec">
          <h2>How The Works Got Done</h2>
          <p>
            After inspecting the situation, I knew there was a lot of work to
            do. As the first move, I started to make a list of needed images and
            content. During this time I started to learn about the products and
            services, and how is the workflow in the company. At the same time,
            I was researching the other companies in the related sectors; Both
            customers and competitors. The design phase had been done in three
            steps starting with creating the needed visuals.
          </p>

          <div className="boratas__step">
            <h3>Step 1 : Visual Content Creating</h3>
            <div className="boratas__photography">
              <h4>Photography & Edit</h4>
              <p>
                Immediately after phase 0, I started shooting photos and videos.
                The shooting subjects mostly were in the production zones, Also
                I used to travel to quarries and construction sites to shoot our
                machines while performing. Each photo needed to be edited, so
                that was the next thing to do. After visiting every quarry I
                used to make a video for that session, as well as for every
                shipping, delivery, event, etc.
              </p>
              <p>
                One challenge during the shootings that I coped with was -
                unlike my previous experiences - whether it's in the production
                zone or the quarries, everyone is super busy with what they're
                doing, and no one has time to explain what is going on, or what
                will happen next. So it was on my own, to figure out what was
                going on, and hunt the moments.
              </p>
              <p>Here are some of my favorite photos I've shot for Boratas.</p>
              <div className="boratas__gallery">
                <BoratasGallery photos={photos} />
              </div>
            </div>
            <div className="boratas__manupilating">
              <div className="text">
                <h4>Photo Manipulation</h4>
                <p>
                  In addition to color correction and editing, I started photo
                  manipulation for use in the next steps. We were looking for a
                  stylish image to show the key values of the company. Values
                  such as High-Technology, Robustness, and Productivity.
                </p>
                <p>
                  Once I saw the machines for the first time, they reminded me
                  of spaceships in sci-fi movies. They were absolutely
                  charismatic, heavy, and designed futuristic. Moreover, the
                  characteristics of these machines such as built-in Hybrid
                  Technology, mobility, and compactness made me imagine sci-fi
                  images again.
                </p>
                <p>
                  So I thought that maybe this could be a proper theme to apply,
                  and after sharing the drafts with the managers, they found it
                  interesting as well. So I started to make a collection of
                  manipulated photos of machines in a way that represents the
                  products as futuristic machines. Here is an example of this
                  collection:
                </p>
              </div>
              <div className="slider">
                <BoratasSlider />
              </div>
            </div>
            <div className="boratas__renders">
              <h4>3D Renders</h4>
              <p>
                Render images of 3D models of the machines are an important
                element in multiple documents. In the past, these renders used
                to be created by engineers using SolidWorks software. Though
                Solidworks is a very powerful software in industrial designing,
                in renders, it's not satisfying enough in terms of providing
                realistic and interesting images. On another hand due to the
                enormous amount of tasks that had to be done by the engineers,
                they didn't have enough time to spend on providing high-quality
                render images.
              </p>
              <p>
                This was something we couldn't skip, so I had to find a solution
                for this matter. Though the company had been generous in
                providing the needs for improving the quality, I tried to find
                an inexpensive solution. I had experience with the software
                Blender, which is an open-source free-to-use 3D software. I
                asked the engineers to export the 3D files properly to import
                them into Blender. After that, I tried different adjustments in
                order to get world-class quality renders. The results were
                absolutely satisfying. And it all ended up without any extra
                cost for the company.
              </p>
              <p>
                Here you can compare SolidWorks' previous renders with the new
                ones created by Blender:
              </p>
              <img
                src={rendersCompare}
                alt="boratas renders comparing"
                className="single-img"
              />
              <p>
                The transformation was absolutely successful. Furthermore, by
                applying some edits and adding backgrounds to the renders, I
                created plenty of mesmerizing images to use in different
                channels. Here are some examples:
              </p>
              <div className="boratas__gallery">
                <BoratasGallery photos={renders} />
              </div>
            </div>
            <div className="boratas__graphics">
              <h4>Graphics</h4>
              <p>
                Thanks to the visual archive that we had gathered previously, I
                had enough material in my hand for use to design. At that stage,
                we set the priorities depending on the day's needs. So in a few
                months, we had new catalogs, flyers, manual handbooks, etc.
              </p>
              <p>
                The most important approach in all of these graphic works was
                facilitating. Since I was working close to the sales department,
                I could see the real feedback on the graphic works. Moreover, I
                used to attend some of the meetings with new customers for
                providing visuals related to the presentations, I could examine
                the functionality and effectiveness of the images on the user.
                This was an uplifting experience, so I had the chance to improve
                the graphics based on the user experience. Here is a few random
                examples of the mentioned works:
              </p>
              <div className="boratas__gallery">
                <BoratasGallery photos={graphics} />
              </div>
            </div>
            <div className="boratas__videos">
              <h4>Videography & Edit</h4>
              <p>
                By using drone footage, 3D animations, and post-production I
                tried to make the videos fun to watch. I didn't want to bore the
                visitors, so I kept them as short as possible. Except for a few
                ones, most of the videos are around 2 minutes. These videos
                could be introductions or educational. In some videos, I used AI
                readers as voice-overs. Here are a few examples of short videos:
              </p>
              <div className="boratas__videos__container">
                <YoutubeEmbed embedId="0VUUr2Jc59o" />
                <YoutubeEmbed embedId="WugFhLzubBA" />
                <YoutubeEmbed embedId="Petv-w7ccPo" />
                <YoutubeEmbed embedId="eH45tpSkJZw" />
                <YoutubeEmbed embedId="0w2IuV2tcNU" />
                <YoutubeEmbed embedId="IxLIRE22t6o" />
              </div>
            </div>
          </div>

          <div className="boratas__step">
            <h3>Step 2 : Brand Design</h3>
            <div className="boratas__print">
              <h4>Print Documents</h4>
              <p>
                After creating new renders and renewing the photo archive, it
                was time to redesign the brochures of each machine. We wanted to
                avoid filling it with too many details, so we decided to keep
                them comprehensive and short.
              </p>
              <p>
                Another example of print documents that I designed after
                creating new renders is product catalogs which are pretty useful
                for customers. Here are a few pages of Boratas Mobile Machines
                catalog.
              </p>
              <div className="boratas__gallery">
                <BoratasGallery photos={prints} />
              </div>
            </div>
            <div className="boratas__socials">
              <h4>Social Media</h4>
              <p>
                In the past, the sales officers were responsible for the
                company's social media. They used to post and contact customers.
                The problem was when an employee was replaced with another
                person, always the new one used to post with a different style.
                Also, they didn't have time to edit the videos and photos.
                Therefore The shared contents were completely random in style,
                quality, and writing.
              </p>
              <p>
                When I started to work I took responsibility for social media
                postings. While I always had been consulting with sales officers
                to learn about what they actually want to achieve from posting
                on social media, and also, what users are looking for in the
                company's social media channels. So with the help of the sales
                department, we built a strategy to follow.
              </p>
              <p>
                Most of the social media posts are photos and videos of
                performing machines, shipping, deliveries, etc. Meanwhile, the
                posts could include national and international events.
              </p>
              <div className="boratas__gallery">
                <BoratasGallery photos={socials} />
              </div>
            </div>
          </div>

          <div className="boratas__step">
            <h3>Step 3 : Web Design</h3>
            <p>
              Boratas have two websites; One is the main website which covers
              the whole products and services of the company while the other one
              is only covering one special type of machinery and its target is
              mainly foreign customers.
            </p>
            <p>
              After the previous tasks got done, the social media channels were
              working fine and the images and videos were looking professional.
              But still, we couldn't see a significant difference in customer
              numbers. Everywhere we were inviting the audience to visit our
              websites, such as Call To Action, which makes sense. But the
              downside was the huge difference in terms of quality between the
              shared content on social channels, and the websites. Additionally,
              the websites were full of bugs and did not function properly.
            </p>
            <p>
              As the first move, we tried to fix the existing websites' problems
              by replacing the old images with new ones through the Admin Panel
              and contacting the web developers who had built the websites.
              These actions worked for one of the two websites. But the mother
              website couldn't get fixed according to the developers' statement.
              So we had to build a new website from scratch.
            </p>
            <p>
              Because of my existing skills in front-end development and my
              previous experience of working in a start-up team, also the fact
              that I had been working for the company for a while and I was able
              to understand the needs and priorities easier, my manager asked me
              to collaborate with the developers and take the responsibility of
              front end for creating a new website as a new task.
            </p>
            <img
              src={boratasWeb}
              alt="boratas product tree"
              className="single-img"
            />
            <div className="boratas__web__objectiv">
              <h4>Objective</h4>
              <p>
                This was absolutely an uplifting task. So we developed a brand
                new website from scratch, starting by designing wireframes for
                each page. Since the website was static, we weren't in need of
                back-end coding or any complex functionality. The most important
                thing for the company was that the visitors can find all of our
                machines in an organized way while being able to find the
                details about the features and specifics of each machine.
              </p>
              <p>
                Another objective was to show the corporate side of the company
                and mesmerize the visitors with amazing images. Thanks to the
                visual archive that had been gathered, this part wasn't a
                problem. But the most challenging part was organizing more than
                70 products with different categories and classifications
                without ending up with a mess. Moreover, most of the machines
                had different models with different dimensions and details.
              </p>
            </div>
            <div className="boratas__web__product">
              <h4>Product Landing</h4>
              <p>
                I started by creating a tree chart in order to demonstrate the
                machines in a categorized way. The first branch is dedicated to
                the mobility of the machines, instead of their types. So All of
                the machines are divided into two general categories, Mobile and
                Stationary. And the next branches relatively were dedicated to
                the machines' main job, their type, and their order in
                multistage crushing & screening applications.
              </p>
              <img
                src={productTree}
                alt="boratas product tree"
                className="single-img"
              />
              <p>
                Starting the categorization by mobility instead of type, solved
                the main problem of product placements on the website. So users
                after choosing between Mobile and Stationary options, get into
                the related page. Whether Mobile or Stationary, there's a
                sidebar, containing links to the machines in the same category,
                so users can always navigate which machine they are checking out
                and what are the other options. Also, I placed a link button
                from Mobile to Stationary and vice versa. On every machine's
                page, we placed plenty of useful information divided by tabs.
              </p>
              <div className="boratas__360">
                <h4>360 Product View</h4>
                <p>
                  As an extra flavor to the products section, I embedded a
                  module that demonstrates a 3D model of each machine on the
                  relative page, in a way that the user can view the model in
                  360 degrees by simply clicking and dragging on the image.
                </p>
                <p>
                  I embed a similar module here so you can simply click and drag
                  to left/right on the image below to see the machine from
                  different angles.
                </p>
                {/* <React360Viewer
              imagesBaseUrl="../media/360/BPS101/"
              imagesCount={50}
              imagesFiletype="jpg"
              mouseDragSpeed={20}
            /> */}
                <div id="container360">
                  <ThreeSixty
                    // onHover={handleMouseWheel}
                    amount={50}
                    imagePath="../media/360/BPS101"
                    fileName="000{index}.jpg"
                  />
                </div>
              </div>
            </div>
            <div className="boratas__web__consultancy">
              <h4>Consultancy Services</h4>
              <p>
                One feature of the website that significantly facilitates the
                sales department's work is the Consultancy Service page.
                Normally when customers want to purchase a stone crushing
                machine, they give some information to the sales officers in
                order to receive an offer from the company. This information
                includes the details of the machine's tasks, such as the
                dimensions of input and output material, the desired capacity,
                the available power source in the worksite, etc. Usually, this
                data used to be received via a phone call or email.
              </p>
              <p>
                We considered a page called Consultancy Service, which is
                basically a set of inputs for required data. The customer can
                quickly fill out the form and submit it, then an email including
                the mentioned data and the customer's contact information is
                sent to the sale's email address. Afterward, the sales officers
                can easily send the customer an offer including the proper
                equipment.
              </p>
              <p>
                This is a simple automated system with lots of time-saving for
                both customers and the sales department.
              </p>
            </div>
            <div className="boratas__web__blog">
              <h4>Blog</h4>
              <p>
                There are always questions. And there are always people
                searching for the answers. Having a blog section on the website
                has two advantages for the company. One is obviously bringing
                more traffic to the website by posting about most asked
                technical questions about the machines. At the same time, it's
                time-saving for sales officers, Because usually customers ask
                these questions and sales officers need to answer the same
                questions over and over. But now with these posted blogs, the
                officers can easily send a link to related posts to the
                customers. On the other hand, it's also a better experience for
                the customers to find their answers by reading a post since
                there are related images and videos included.
              </p>
            </div>
            <div className="boratas__web__seo">
              <h4>SEO Friendly Structure</h4>
              <p>
                In recent times SEO is more than just using keywords. There are
                standards that should be considered. For example, I designed the
                structure of the website in a way that every single page can be
                reached by a maximum of three clicks from the home page, which
                is essential for search engine optimization.
              </p>
              <p>
                Another thing that we considered for this matter is creating an
                XML sitemap that can be read by search engines in order to
                navigate through different pages which is super effective for
                SEO.
              </p>
            </div>
            <div className="boratas__web__next">
              <h4>What's next?</h4>
              <p>
                This is only the beginning of a new chapter. There are lots of
                ideas on the paper which haven't been done yet. One of the most
                needed, missing features is a powerful search system through the
                website. As a front-end developer, I don't have the knowledge to
                build a search system. However, I considered the placement of
                the search bar and proper tags for each section and element so
                that in the future, a back-end developer could add the missing
                features.
              </p>
            </div>
          </div>
        </section>

        <section id="boratas-conclusion" className="container boratas-sec">
          <h2>Conclusion</h2>
          <p>
            The main goal has been upgrading the overall image of the company in
            terms of quality, aesthetics, and clarity. While facilitating the
            interactions between customers and the company. I believe that we
            got the job done with team-working. Also, according to the number of
            orders, we are able to see an increase in the initial interest of
            new customers in going further to contact with the company.
            Moreover, the company is receiving more applicants for sales
            representatives in European and African countries which shows the
            existing potential in partners' eyes as well.
          </p>
          <p>
            Of course, this is the result of almost 10 years of effort by each
            member of the group, and I am glad that I was able to have my role
            done in this journey.
          </p>
        </section>

        <section id="buts" className="container">
          <ButtonFullWidth
            buttonText="Go Back To Projects"
            linkTo="/Projects"
          />
        </section>
      </div>
    </div>
  );
}

export default Boratas;
