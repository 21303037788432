import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MdOutlineContactPage, MdOutlineArrowBack } from "react-icons/md";

const ButtonRoot = styled.div`
  max-width: max-content;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`;

const StyledLink = styled(Link)`
  background-color: var(--primary);
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  transition: background-color 0.3s, color 0.3s;
  padding: 0px 32px;

  &:hover {
    background-color: white;
    color: var(--primary);
  }
`;

const ButtonWrappedWidth = ({ buttonText, linkTo }) => {
  return (
    <ButtonRoot>
      <StyledLink to={linkTo}>{buttonText}</StyledLink>
    </ButtonRoot>
  );
};

export default ButtonWrappedWidth;
